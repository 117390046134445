<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useLocale, useMixpanel } from "@/composables";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { storeToRefs } from "pinia";
import useUserActionsStore from "../../store";
import type { BulkUserActionResponse } from "../../types";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
];

const revokeInvitationData = ref<{ emails: string[] }>({
  emails: [],
});

const { locale } = useLocale();
const payload = computed(() => {
  const emails = revokeInvitationData.value.emails.join(";");
  return {
    command: "revoke_by_email",
    data: emails,
    languagecode: locale.value,
  };
});

const { t } = useI18n();
const { performUserAction } = useUserActionsStore();
const { track } = useMixpanel();
const { activeAction, isActionRequestPending } = storeToRefs(
  useUserActionsStore(),
);

const revokeInvitations = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/ActivityQueue",
      method: "PUT",
      body: payload.value,
    },
    successMessage: t("organisation.generic_action_success", {
      n: revokeInvitationData.value.emails.length,
      action: t("organisation.action.revoke_invitation"),
    }),
    errorMessage: t("organisation.generic_action_error", {
      n: revokeInvitationData.value.emails.length,
      action: t("organisation.action.revoke_invitation"),
    }),
  });

  track("userRevokeInvitation", {
    user_count: revokeInvitationData.value.emails.length,
    action_mode: "bulk_email",
  });
};
</script>
<template>
  <BulkActionsModal
    :isOpen="activeAction === 'bulkRevokeInvitation'"
    :data="revokeInvitationData"
    :steps="steps"
    :title="t('organisation.revoke_invitation')"
    :subtitle="t('organisation.modal_revoke_invitation_description')"
    :confirmButtonProps="{
      label: t('organisation.revoke_invitation'),
      variant: 'primary',
      isLoading: isActionRequestPending,
    }"
    @confirm="revokeInvitations()"
    @close="activeAction = null"
  />
</template>
