<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import { GRange, GRadioGroup, GTooltip } from "@/components";
import { useCoursesTab, type CourseTableDataRow } from "./useCoursesTab";
import {
  useCourseStore,
  useExportStore,
  useFlagStore,
  useWebSessionStore,
} from "@/stores";
import { exportToCSV, exportToExcel, formatLtiData } from "@/utils/export";
import ExportModal from "../components/ExportModal.vue";

import { useLocale } from "@/composables";
import { useDownloadScorm } from "../components/useDownloadScorm";

import {
  ContentMode,
  LessonStatusFail,
  LessonStatusSuccess,
  RemoteScormContentType,
  type ContentRemoteScorm,
  type ContentRS,
  type FormatLtiDataOptions,
  type MultiLanguageBundledContentRS,
  type TemplateAttributes,
} from "@/types/export";
import { type CourseDetails } from "@/types/course";
import { type Course } from "@/types/course";

const { t } = useI18n();

const courseStore = useCourseStore();

const { locale } = useLocale();

type ExportModalProps = {
  isOpen: boolean;
  selectedRows: CourseTableDataRow[];
};

const props = defineProps<ExportModalProps>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "exportSucceeded"): void;
}>();

const exportStore = useExportStore();
const {
  selectedExportType,
  shouldIncludeMetadata,
  shouldExportMultiLanguage,
  selectedFileFormat,
} = storeToRefs(exportStore);

const { courses } = storeToRefs(courseStore);
const { trackExportCourses } = useCoursesTab();

const contentModes = [
  { label: t("contentSelection.lessons_test"), value: ContentMode.BOTH },
  { label: t("contentSelection.lessons"), value: ContentMode.LESSONS },
  { label: t("contentSelection.test"), value: ContentMode.TEST },
];

const selectedContentModesLTI = ref<ContentMode[]>([]);
const selectedContentModeScorm = ref<ContentMode>(ContentMode.BOTH);

const lessonStatusSuccessOptions = [
  { label: "completed", value: LessonStatusSuccess.Completed },
  { label: "passed", value: LessonStatusSuccess.Passed },
];
const lessonStatusFailOptions = [
  { label: "incomplete", value: LessonStatusFail.Incomplete },
  { label: "failed", value: LessonStatusFail.Failed },
];

const BASE_THRESHOLD = 70;

const lessonStatusSuccess = ref(LessonStatusSuccess.Completed);
const lessonStatusFail = ref(LessonStatusFail.Incomplete);
const threshold = ref(BASE_THRESHOLD);

const dataSource = computed(() => {
  const result: CourseDetails[] = [];

  props.selectedRows.forEach((row) => {
    const courseDetails = courseStore.coursesDetails.find(
      (courseDetails) => courseDetails.CourseId === row.id,
    );
    if (courseDetails) {
      result.push(courseDetails);
    }
  });
  return result;
});

const formattedDataColumns = computed(() => {
  const columns: FormatLtiDataOptions<CourseDetails>["columns"] = [];

  const metadataColumns: FormatLtiDataOptions<CourseDetails>["columns"] = [
    {
      id: "CourseId",
      header: t("contentSelection.course_id"),
    },
    {
      id: "CourseCode",
      header: t("contentSelection.course_code"),
    },
    {
      id: "CourseName",
      header: t("contentSelection.course_name"),
    },
    {
      id: "CourseTitle",
      header: t("contentSelection.course_title"),
    },
    {
      id: "Url",
      header: t("contentSelection.course_link"),
    },
    {
      id: "ImageUrlPortrait",
      header: t("contentSelection.image_portrait"),
    },
    {
      id: "ImageUrlSquare",
      header: t("contentSelection.image_square"),
    },
    {
      id: "ImageUrlLandscape",
      header: t("contentSelection.image_landscape"),
    },
    {
      id: "Description",
      header: t("contentSelection.course_description"),
    },
    {
      id: "Tags",
      header: t("contentSelection.tags"),
    },
    {
      id: "Duration",
      header: t("contentSelection.duration"),
    },
    {
      id: "PublishDate",
      header: t("contentSelection.date_of_publication"),
    },
    {
      id: "PublicationStatus",
      header: t("contentSelection.publication_status"),
    },
    {
      id: "Language",
      header: t("contentSelection.language"),
    },
    {
      id: "LanguageCode",
      header: t("contentSelection.language_code"),
    },
    {
      id: "CountryCode",
      header: t("contentSelection.country_code"),
    },
    {
      id: "CategoryId",
      header: t("contentSelection.category_id"),
    },
    {
      id: "Category",
      header: t("contentSelection.category"),
    },
    {
      id: "CoursecontainerId",
      header: t("contentSelection.container_id"),
    },
    {
      id: "Coursecontainer",
      header: t("contentSelection.course_container"),
    },
    {
      id: "TestAvailable",
      header: t("contentSelection.test_available"),
    },
    {
      id: "MobileFriendly",
      header: t("contentSelection.mobile_friendly"),
    },
    {
      id: "CourseAreaId",
      header: t("contentSelection.course_area_id"),
    },
    {
      id: "ImageIcon",
      header: t("contentSelection.image_icon"),
    },
    {
      id: "ImageSquareNoText",
      header: t("contentSelection.image_square_no_text"),
    },
    {
      id: "TrainingType",
      header: t("contentSelection.training_type"),
    },
  ];

  const ltiFields: Record<
    "lti" | "lti13",
    Record<ContentMode, FormatLtiDataOptions<CourseDetails>["columns"][0]>
  > = {
    lti: {
      [ContentMode.BOTH]: {
        id: "LtiLaunchUrlComplete",
        header: "LtiLaunchUrlComplete",
      },
      [ContentMode.LESSONS]: {
        id: "LtiLaunchUrlContentOnly",
        header: "LtiLaunchUrlContentOnly",
      },
      [ContentMode.TEST]: {
        id: "LtiLaunchUrlTestOnly",
        header: "LtiLaunchUrlTestOnly",
      },
    },
    lti13: {
      [ContentMode.BOTH]: {
        id: "Lti13LaunchUrlComplete",
        header: "Lti13LaunchUrlComplete",
      },
      [ContentMode.LESSONS]: {
        id: "Lti13LaunchUrlContentOnly",
        header: "Lti13LaunchUrlContentOnly",
      },
      [ContentMode.TEST]: {
        id: "Lti13LaunchUrlTestOnly",
        header: "Lti13LaunchUrlTestOnly",
      },
    },
  };

  const cellsCountAfterLtiFields = 21;
  const indexToInsertLtiFields =
    metadataColumns.length - cellsCountAfterLtiFields;
  if (selectedExportType.value === "lti" && !shouldIncludeMetadata.value) {
    if (selectedContentModesLTI.value.includes(ContentMode.BOTH)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[ltiFields.lti[ContentMode.BOTH], ltiFields.lti13[ContentMode.BOTH]],
      );
    }

    if (selectedContentModesLTI.value.includes(ContentMode.LESSONS)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[
          ltiFields.lti[ContentMode.LESSONS],
          ltiFields.lti13[ContentMode.LESSONS],
        ],
      );
    }

    if (selectedContentModesLTI.value.includes(ContentMode.TEST)) {
      columns.splice(
        indexToInsertLtiFields,
        0,
        ...[ltiFields.lti[ContentMode.TEST], ltiFields.lti13[ContentMode.TEST]],
      );
    }
  }

  if (shouldIncludeMetadata.value) {
    columns.push(...metadataColumns);
    columns.splice(
      indexToInsertLtiFields,
      0,
      ...Object.values(ltiFields.lti13),
    );
    columns.splice(indexToInsertLtiFields, 0, ...Object.values(ltiFields.lti));
  }

  return columns;
});

const remoteScormLessonStatusSuccess = computed(() => {
  switch (selectedContentModeScorm.value) {
    case ContentMode.TEST:
      return LessonStatusSuccess.Passed;
    case ContentMode.LESSONS:
      return LessonStatusSuccess.Completed;
    default:
      return lessonStatusSuccess.value;
  }
});

const remoteScormLessonStatusFail = computed(() => {
  switch (selectedContentModeScorm.value) {
    case ContentMode.TEST:
      return LessonStatusFail.Failed;
    case ContentMode.LESSONS:
      return LessonStatusFail.Incomplete;
    default:
      return lessonStatusFail.value;
  }
});

const getMultiLanguageContentWrapperName = (courses: Course[]): string => {
  let containerName = getMultiLanguageDefaultCourse(courses).CourseName;

  // replace the language code in the found container name with multi-lang
  containerName = containerName.replace(
    /(\d{4})-(\w{2}-\w{2})-(.*)/,
    `$1-multi-lang-$3`,
  );

  return containerName;
};

const getMultiLanguageDefaultCourse = (courses: Course[]): Course => {
  const currentLang = locale.value.split("-")[0].toUpperCase();
  return (
    courses.find((course) => course.Lang === currentLang) ||
    courses.find((course) => course.Lang === "EN") ||
    courses[0]
  );
};

const { getLanguageRegionByLanguageCode } = useWebSessionStore();
const formatCoursesScormData = (courseList: Course[]): ContentRS[] => {
  return courseList.map((course) => {
    return {
      id: course.CourseCode?.toString(),
      name: course.CourseName,
      title: course.properties?.title,
      language: course.Lang?.toUpperCase(),
      locale: getLanguageRegionByLanguageCode(course.Lang),
    };
  });
};

const {
  downloadScormPackages,
  getLanguageOptions,
  downloadRemoteScormPackages,
} = useDownloadScorm();

const formatScormCourseList = ():
  | ContentRS[]
  | MultiLanguageBundledContentRS[] => {
  if (shouldExportMultiLanguage.value) {
    const courseContainerIds = props.selectedRows.map((row) => row.containerId);
    const uniqueCourseContainerIds = [...new Set(courseContainerIds)];
    return uniqueCourseContainerIds.map((containerId) => {
      const coursesInContainer = courses.value.filter(
        (course) =>
          course.CourseContainerId === containerId &&
          course.PublicationStatus === 1,
      );
      const contentList = formatCoursesScormData(coursesInContainer);
      const item = {
        containerName: getMultiLanguageContentWrapperName(coursesInContainer),
        contentListInContainer: contentList,
        languageOptionList: getLanguageOptions(contentList),
      };
      return item;
    });
  } else {
    const originalCourseList = props.selectedRows.map((row) =>
      courses.value.find((course) => course.CourseCode === row.courseCode),
    );
    return formatCoursesScormData(originalCourseList);
  }
};

const formatRemoteScormCourseList = (): ContentRemoteScorm[] => {
  const courseList = props.selectedRows.map((row) =>
    courses.value.find((course) => course.CourseCode === row.courseCode),
  );
  if (shouldExportMultiLanguage.value) {
    const courseContainerIds = props.selectedRows.map((row) => row.containerId);
    const uniqueCourseContainerIds = [...new Set(courseContainerIds)];
    return uniqueCourseContainerIds.map((containerId) => {
      const coursesInContainer = courses.value.filter(
        (course) =>
          course.CourseContainerId === containerId &&
          course.PublicationStatus === 1,
      );
      return {
        containerName: getMultiLanguageContentWrapperName(coursesInContainer),
        contentType: RemoteScormContentType.Course,
        contentId: containerId.toString(),
        isMultipleLanguage: true,
        title:
          getMultiLanguageDefaultCourse(coursesInContainer).properties.title,
        locale: locale.value,
      };
    });
  } else {
    return courseList.map((course) => ({
      contentId: course.CourseCode,
      contentType: RemoteScormContentType.Course,
      isMultipleLanguage: false,
      title: course.CourseName,
      locale: getLanguageRegionByLanguageCode(course.Lang),
    }));
  }
};

const handleDownloadCourseScormPackages = async () => {
  if (!props.selectedRows.length) return;

  const templateAttributes: TemplateAttributes = {
    mode: selectedContentModeScorm.value,
    mixedTrainingResultComplete: remoteScormLessonStatusSuccess.value,
    mixedTrainingResultIncomplete: remoteScormLessonStatusFail.value,
    threshold: threshold.value,
  };
  try {
    if (flagStore.scromWithLMSConnectorFlag) {
      const coursesToDownload = formatRemoteScormCourseList();
      await downloadRemoteScormPackages(coursesToDownload, templateAttributes);
    } else {
      const coursesToDownload = formatScormCourseList();

      await downloadScormPackages(
        "COURSE",
        coursesToDownload,
        templateAttributes,
      );
    }

    if (shouldIncludeMetadata.value) {
      const metadata = formatLtiData({
        dataSource: dataSource.value as any,
        columns: formattedDataColumns.value as any,
      });
      if (selectedFileFormat.value === "csv") exportToCSV(metadata, "metadata");
      else exportToExcel(metadata, "metadata");
    }

    trackExportCourses(props.selectedRows, shouldExportMultiLanguage.value);
    emit("exportSucceeded");
  } catch (error) {
    console.error(error);
  }
};
const flagStore = useFlagStore();
const { multiLangSCOExportFlag } = storeToRefs(flagStore);
const showMultiLanguageScoExport = computed(() => {
  return selectedExportType.value == "scorm" && multiLangSCOExportFlag.value;
});

const handleLTIExportSuccess = () => {
  trackExportCourses(props.selectedRows, shouldExportMultiLanguage.value);
  emit("exportSucceeded");
};

watch(selectedContentModeScorm, () => {
  threshold.value = BASE_THRESHOLD;
});
</script>
<template>
  <ExportModal
    :isOpen="isOpen"
    ltiFileName="gh-course-export"
    :data="{
      dataSource: dataSource as any,
      columns: formattedDataColumns as any,
    }"
    :exportTypes="['lti', 'scorm', 'metadata']"
    @close="$emit('close')"
    @exportToScorm="handleDownloadCourseScormPackages()"
    @exportToLtiSuccess="handleLTIExportSuccess"
  >
    <GRadioGroup
      v-if="selectedExportType === 'scorm'"
      class="mb-6"
      name="selectedContentFormats"
      v-model="selectedContentModeScorm"
      :options="contentModes"
    >
      <template #label>{{
        t("contentSelection.scorm_package_options")
      }}</template>
    </GRadioGroup>
    <div
      v-if="selectedExportType === 'lti'"
      class="mb-2 flex flex-col gap-2 py-4"
    >
      <label class="label-text mb-2 inline-block font-medium text-black">{{
        t("contentSelection.lti_url_options")
      }}</label>
      <label
        v-for="mode in contentModes"
        :key="mode.value"
        class="flex items-center gap-2"
      >
        <input
          type="checkbox"
          :value="mode.value"
          v-model="selectedContentModesLTI"
          class="checkbox checkbox-primary checkbox-sm"
        />{{ mode.label }}</label
      >
    </div>
    <div v-if="selectedExportType === 'scorm'" class="flex flex-col gap-4">
      <GRange
        :disabled="selectedContentModeScorm === ContentMode.TEST"
        :modelValue="threshold"
        @update:modelValue="threshold = $event"
        :step="5"
        :label="t('contentSelection.threshold_pass')"
      />
      <GRadioGroup
        name="lessonStatusSuccess"
        :modelValue="remoteScormLessonStatusSuccess"
        @update:modelValue="lessonStatusSuccess = $event"
        :options="lessonStatusSuccessOptions"
        :isDisabled="selectedContentModeScorm !== ContentMode.BOTH"
      >
        <template #label>
          <span> {{ t("contentSelection.status_pass") }}</span>
          <GTooltip class="bg-gray-900 p-2 text-white">
            <template #content>
              {{ t("contentSelection.status_pass_tooltip") }}
            </template>
          </GTooltip>
        </template>
      </GRadioGroup>
      <GRadioGroup
        name="lessonStatusFail"
        :modelValue="remoteScormLessonStatusFail"
        @update:modelValue="lessonStatusFail = $event"
        :options="lessonStatusFailOptions"
        :isDisabled="selectedContentModeScorm !== ContentMode.BOTH"
      >
        <template #label>
          <span> {{ t("contentSelection.status_pass_not") }}</span>
          <GTooltip class="bg-gray-900 p-2 text-white">
            <template #content>
              {{ t("contentSelection.status_pass_not_tooltip") }}
            </template>
          </GTooltip>
        </template>
      </GRadioGroup>
    </div>
    <span
      v-if="showMultiLanguageScoExport"
      class="flex items-center gap-2 border-t py-4"
      ><input
        type="checkbox"
        v-model="shouldExportMultiLanguage"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.export_multi_lang") }}</span
    >
    <span
      v-if="selectedExportType !== 'metadata'"
      class="flex items-center gap-2 border-t py-4"
      ><input
        type="checkbox"
        v-model="shouldIncludeMetadata"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.add_content_meta") }}</span
    >
  </ExportModal>
</template>
