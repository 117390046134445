<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { GDropdown } from "@/components";
import { useUserStore } from "@/stores";

const props = defineProps<{
  disabled?: boolean;
  restrictedToMyTeams?: boolean;
}>();

const rolesModel = defineModel<string[] | null>({
  required: true,
  default: [],
});

const { t } = useI18n();
const userStore = useUserStore();
const { roleCodeToLabel } = userStore;

const roleOptions = computed(() => {
  return Object.entries(roleCodeToLabel).map(([roleCode, roleName]) => ({
    label: roleName,
    value: roleCode,
  }));
});
</script>
<template>
  <GDropdown
    searchable
    isMultiChoice
    hasDefault
    :is-disabled="props.disabled"
    :label="t('common.select_roles')"
    :options="roleOptions"
    :value="rolesModel"
    @change="rolesModel = $event as string[]"
  />
</template>
