<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { z } from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";

import { TextInput, GRange } from "@/components";
import ConnectionCard from "./ConnectionCard.vue";
import type { Lti13Key } from "./Lti13Tab.vue";
import { formatPascalCaseToSnake } from "@/utils/misc";

const { t } = useI18n();
const schemaDefinition = z.object({
  Name: z.string().min(1, t("validationMessages.required")),
  ClientId: z.string().min(1, t("validationMessages.required")),
  DeploymentId: z.string().min(1, t("validationMessages.required")),
  PlatformId: z.string().min(1, t("validationMessages.required")),
  PublicKeySetUrl: z
    .string()
    .min(1, t("validationMessages.required"))
    .url(t("validationMessages.url")),
  AccessTokenUrl: z
    .string()
    .min(1, t("validationMessages.required"))
    .url(t("validationMessages.url")),
  AuthenticationRequestUrl: z
    .string()
    .min(1, t("validationMessages.required"))
    .url(t("validationMessages.url")),
  TestWeighing: z.number().min(0).max(100),
});

type SchemaDefinition = z.infer<typeof schemaDefinition>;

const props = defineProps<{
  connectionKey: Lti13Key;
  isInEditMode: boolean;
}>();

const emit = defineEmits<{
  (e: "save", value: Lti13Key): void;
  (e: "delete", value: number): void;
  (e: "edit", key: Lti13Key): void;
  (e: "cancel"): void;
}>();

const title = computed(() => {
  return props.connectionKey.Id === -1
    ? t("connectionMethods.new_key")
    : props.connectionKey.Name;
});

const validationSchema = toTypedSchema(schemaDefinition);

const { handleSubmit, isSubmitting, setErrors, values, setFieldValue } =
  useForm<SchemaDefinition>({
    validationSchema: validationSchema,
    initialValues: props.connectionKey,
  });

const handleAddLti13Key = handleSubmit(
  (data) => {
    emit("save", {
      Id: props.connectionKey.Id,
      CompanyId: props.connectionKey.CompanyId,
      ContentWeighing: 100 - data.TestWeighing,
      ...data,
    } as Lti13Key);
  },
  ({ errors }) => {
    setErrors(errors);
  },
);

const LTI13Fields: ReadonlyArray<keyof SchemaDefinition> = [
  "Name",
  "PlatformId",
  "ClientId",
  "DeploymentId",
  "PublicKeySetUrl",
  "AccessTokenUrl",
  "AuthenticationRequestUrl",
  "TestWeighing",
];
</script>
<template>
  <ConnectionCard
    :title="title"
    :isInEditMode="props.isInEditMode"
    :isSubmitting="isSubmitting"
    @edit="emit('edit', connectionKey)"
    @cancel="emit('cancel')"
    @delete="emit('delete', connectionKey.Id)"
    @save="handleAddLti13Key"
  >
    <div class="grid grid-flow-row grid-cols-2 gap-4">
      <TextInput
        v-for="fieldName in LTI13Fields.filter((f) => f !== 'TestWeighing')"
        :key="fieldName"
        :name="fieldName"
        :readonly="!props.isInEditMode"
        :label="t(`connectionMethods.${formatPascalCaseToSnake(fieldName)}`)"
        :labelClasses="['capitalize']"
        :modelValue="values[fieldName]"
        @update:modelValue="setFieldValue(fieldName, $event)"
        containerClasses="w-full"
      />
      <GRange
        :id="`${props.connectionKey.Id}-range`"
        :label="t('connectionMethods.weight')"
        :weight-labels="[
          t('connectionMethods.test'),
          t('connectionMethods.lessons'),
        ]"
        class="w-full"
        :modelValue="values['TestWeighing']"
        @update:modelValue="setFieldValue('TestWeighing', $event)"
        :disabled="!props.isInEditMode"
      />
    </div>
  </ConnectionCard>
</template>
