<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { endOfToday, startOfDay, sub } from "date-fns";

import { PageLayout, GDatePicker, GRestrictedTabView } from "@/components";
import ActiveUsersSection from "./components/ActiveUsersSection.vue";
import CompletedAsmSection from "./components/CompletedAsmSection.vue";
import ActivityPerAsmSection from "./components/ActivityPerAsmSection.vue";
import { useAssessmentsStore } from "@/stores";

const asmStore = useAssessmentsStore();

const { t } = useI18n();

const dateRange = ref<[Date, Date]>([
  sub(startOfDay(new Date()), { days: 6 }),
  endOfToday(),
]);

const startDateTimestamp = computed(() =>
  convertToUTCTimestamp(dateRange.value[0]),
);
const endDateTimestamp = computed(() =>
  convertToUTCTimestamp(dateRange.value[1]),
);

const convertToUTCTimestamp = (date: Date) => {
  const MILLISECONDS_PER_MINUTE = 60000;
  return date.getTime() - date.getTimezoneOffset() * MILLISECONDS_PER_MINUTE;
};
</script>
<template>
  <PageLayout :title="t('assessments.title')" :heading="t('assessments.title')">
    <template #primaryAction>
      <GDatePicker
        v-model="dateRange"
        :label="t('common.date')"
        class="w-72"
      ></GDatePicker>
    </template>
    <GRestrictedTabView v-if="!asmStore.isAssessmentsUser" />
    <div
      class="grid items-stretch gap-4 lg:grid-cols-3"
      v-if="
        asmStore.isAssessmentsUser && startDateTimestamp && endDateTimestamp
      "
    >
      <ActiveUsersSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
      <CompletedAsmSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
      <ActivityPerAsmSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
    </div>
  </PageLayout>
</template>
