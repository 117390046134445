import { defineStore } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, type RouteLocationNormalized } from "vue-router";

import { useWebSessionStore } from "./websession";
import { getToken } from "@/utils/getToken";
import type { UserRoleValue } from "@/types/websession";
import { IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY } from "@/constants";

type UserRole =
  | "admin"
  | "student"
  | "connector"
  | "accountManager"
  | "reporter"
  | "marketer"
  | "groupReporter";

export const userRoles: Record<UserRole, UserRoleValue> = {
  admin: "ADMIN",
  student: "ST",
  accountManager: "AM",
  connector: "LMSC",
  marketer: "MKT",
  reporter: "RPT",
  groupReporter: "GRPT",
};

export const DASHBOARD_ROLES = [
  userRoles.admin,
  userRoles.connector,
  userRoles.reporter,
  userRoles.marketer,
  userRoles.accountManager,
  userRoles.groupReporter,
];

export const useUserStore = defineStore("user", () => {
  const webSessionStore = useWebSessionStore();

  const roleCodeToLabel = computed(() => {
    const { t } = useI18n();
    return {
      [userRoles.admin]: t("organisation.roles.admin"),
      [userRoles.connector]: t("organisation.roles.connector"),
      [userRoles.accountManager]: t("organisation.roles.account_manager"),
      [userRoles.marketer]: t("organisation.roles.marketer"),
      [userRoles.reporter]: t("organisation.roles.reporter"),
      [userRoles.groupReporter]: t("organisation.roles.team_reporter"),
      [userRoles.student]: t("organisation.roles.student"),
    };
  });

  const isAuthenticated = computed(() => {
    return !!getToken() && webSessionStore.webSession?.id !== -1;
  });

  const user = computed(() => {
    if (!webSessionStore.webSession) return null;
    return webSessionStore.webSession.CurrentUser;
  });

  const currentUserRoles = computed(() => {
    if (!webSessionStore.webSession) return [];
    return (user.value.Role as UserRoleValue[]) || null;
  });

  const router = useRouter();
  const isAuthorizedForRoute = (route: RouteLocationNormalized | string) => {
    if (typeof route === "string") {
      route = router.resolve(route);
    }
    return !!(
      isAuthenticated.value &&
      currentUserRoles.value?.some((role) =>
        (route as RouteLocationNormalized).matched.some((matched) =>
          matched.meta.allowedRoles?.includes(role),
        ),
      )
    );
  };

  const optedInNewDashboard = computed(() => {
    return (
      localStorage.getItem(IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY) === "true"
    );
  });

  return {
    currentUserRoles,
    roleCodeToLabel,
    isAuthorizedForRoute,
    user,
    optedInNewDashboard,
  };
});
