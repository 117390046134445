<script setup lang="ts">
import { computed } from "vue";
import { GDropdown, type DropdownOption } from "@/components";
import { type ActionOption } from "@/patterns";

const emit = defineEmits<{
  (event: "open"): void;
  (event: "close"): void;
}>();

const props = defineProps<{
  options: ActionOption[];
  activeAction;
}>();

const dropdownOptions = computed<DropdownOption[]>(() =>
  props.options.map(({ id, ...restAction }) => {
    return {
      value: id,
      ...restAction,
    };
  }),
);

const dispatchAction = (selectedAction: string) => {
  return props.options.find((action) => action.id === selectedAction)?.action();
};
</script>
<template>
  <GDropdown
    :options="dropdownOptions"
    :value="activeAction"
    @change="dispatchAction($event as string)"
    @open="emit('open')"
    @close="emit('close')"
  >
    <template #trigger="{ setElementRef, isMenuOpen }">
      <slot
        name="trigger"
        :setElementRef="setElementRef"
        :isMenuOpen="isMenuOpen"
      />
    </template>
  </GDropdown>
</template>
