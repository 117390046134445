<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { GButton, GModal } from "@/components";
import useUserActionsStore from "../../store";
import ChooseTeams from "./ChooseTeams.vue";
import CreateNewTeam from "./CreateNewTeam.vue";
import { useTeamsStore } from "@/stores";
import PlusIcon from "@/assets/images/icons/plus.svg";
import { DIALOG_TRANSITION_MS } from "@/components/GModal";
import type { BulkUserActionResponse } from "../../types";
import { useMixpanel } from "@/composables";

const { t } = useI18n();
const { performUserAction, closeModal } = useUserActionsStore();
const { activeAction, selectedUserIds, allowedIdsForAction, activeRow } =
  storeToRefs(useUserActionsStore());
const teamsStore = useTeamsStore();

const currentStep = ref<"addToTeam" | "createNewTeam">("addToTeam");
const selectedTeams = ref<number[]>([]);
const { track } = useMixpanel();

const addUsersToTeams = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/TagPerson",
      method: "POST",
      body: {
        TagId: selectedTeams.value,
        PersonId: allowedIdsForAction.value,
        Email: [],
        StartDate: new Date().toISOString(),
        DurationUnit: "YYYY",
        Duration: 100,
      },
    },
    successMessage: t("organisation.generic_action_success", {
      n: allowedIdsForAction.value.length,
      action: t("organisation.action.add_to_team"),
    }),
    errorMessage: t(
      "organisation.generic_action_error",
      { action: t("organisation.action.add_to_team") },
      { plural: selectedUserIds.value.length },
    ),
  });
  track("userAddToTeam", {
    user_count: selectedUserIds.value.length,
    action_mode: activeRow.value ? "single" : "bulk_selection",
  });
};

const handlePrimaryAction = async () => {
  if (currentStep.value === "addToTeam") {
    await addUsersToTeams();
  } else {
    await teamsStore.addNewTeam();
    selectedTeams.value.push(teamsStore.newTeam.Id);
    currentStep.value = "addToTeam";
  }
};

const isPrimaryActionDisabled = computed(() => {
  return currentStep.value === "addToTeam"
    ? selectedTeams.value.length === 0
    : teamsStore.newTeamPayload.Name.length === 0;
});

const handleCloseModal = () => {
  setTimeout(() => {
    closeModal();
    selectedTeams.value = [];
    currentStep.value = "addToTeam";
    teamsStore.newTeamPayload = {
      Name: "",
    };
  }, DIALOG_TRANSITION_MS);
};
</script>
<template>
  <GModal
    :isOpen="activeAction === 'addToTeam'"
    scrollBehaviour="modal"
    :title="t('organisation.add_to_team')"
    @close="handleCloseModal"
    data-testId="add-to-team-modal"
    class="w-full max-w-[672px]"
  >
    <ChooseTeams v-if="currentStep === 'addToTeam'" v-model="selectedTeams" />
    <CreateNewTeam v-else v-model="teamsStore.newTeamPayload.Name" />

    <template #actions>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex gap-2">
          <GButton
            v-if="currentStep === 'createNewTeam'"
            class="px-4"
            variant="link"
            @click="currentStep = 'addToTeam'"
            >{{ t("common.previous") }}
          </GButton>
          <GButton
            @click="handlePrimaryAction"
            :disabled="isPrimaryActionDisabled"
            >{{ t("organisation.add_to_team") }}</GButton
          >
        </div>
        <GButton
          v-if="currentStep === 'addToTeam'"
          class="font-normal"
          variant="link"
          @click="currentStep = 'createNewTeam'"
          >{{ t("organisation.new_team") }}
          <template #prefixIcon><PlusIcon /></template>
        </GButton>
      </div>
    </template>
  </GModal>
</template>
