<script lang="ts" setup>
import { GTooltip } from "@/components";
import { useTemplateRef } from "vue";
defineProps<{
  list: string[];
}>();
const listRef = useTemplateRef("list");
</script>
<template>
  <GTooltip
    class="bg-white [box-shadow:0px_4px_8px_0px_rgba(0,0,0,0.24)]"
    :hasArrow="false"
    :displayDelay="300"
  >
    <template #reference>
      <span
        ref="list"
        class="block w-48 overflow-clip text-ellipsis text-nowrap"
      >
        {{ list.join(", ") || "-" }}
      </span>
    </template>
    <template #content v-if="listRef?.offsetWidth < listRef?.scrollWidth">
      <ul class="list-none">
        <li v-for="item in list" :key="item" class="py-4 pl-5 pr-11">
          {{ item || "-" }}
        </li>
      </ul>
    </template>
  </GTooltip>
</template>
