<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GAlert, GButton, GModal } from "@/components";
import type { UserActionId } from "../types";
import { computed } from "vue";

const props = defineProps<{
  actionId: UserActionId;
  isOpen: boolean;
  title: string;
  description: string;
  excludedCount?: number;
  confirmButtonProps: {
    text: string;
    variant: "primary" | "danger";
    disabled?: boolean;
    isLoading?: boolean;
  };
}>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "confirm"): void;
}>();

const { t } = useI18n();

const wrongStatusMessage = computed(() => {
  if (!props.actionId) return "";

  // There are two different messages depending on the number of allowed statuses per action id
  const messageType =
    props.actionId === "revokeInvitation"
      ? "one_status_message"
      : "multiple_statuses_message";

  return t(
    `organisation.wrong_status.${messageType}`,
    {
      count: props.excludedCount,
      statuses: t(
        `organisation.wrong_status.available_statuses_per_action_id.${props.actionId}`,
      ),
    },
    { plural: props.excludedCount },
  );
});
</script>
<template>
  <GModal
    :isOpen="props.isOpen"
    scrollBehaviour="modal"
    :title="props.title"
    @close="emit('close')"
    class="w-full max-w-[480px]"
  >
    <div class="mb-5 grid gap-6">
      <p>{{ props.description }}</p>
      <GAlert
        v-if="excludedCount"
        :dismissible="false"
        variant="warning"
        :message="wrongStatusMessage"
      />
      <slot />
    </div>

    <template #actions>
      <div class="flex flex-row-reverse gap-2">
        <GButton
          v-bind="props.confirmButtonProps"
          class="px-4"
          @click="emit('confirm')"
        >
          {{ props.confirmButtonProps.text }}
        </GButton>
        <GButton variant="link" class="px-4" @click="emit('close')">
          {{ t("common.cancel") }}
        </GButton>
      </div>
    </template>
  </GModal>
</template>
