<script setup lang="ts" generic="T extends Record<string, string>">
import { useI18n } from "vue-i18n";
import { GButton } from "@/components";
import { SearchInput } from "@/patterns";
import { useBreakpoints } from "@/composables";
import { useSlots } from "vue";

const props = withDefaults(
  defineProps<{ isSecondaryFiltersVisible?: boolean }>(),
  { isSecondaryFiltersVisible: false },
);

const searchQuery = defineModel<string>("search", {
  default: "",
});

const emit = defineEmits<{
  (e: "reset"): void;
  (e: "toggleSecondaryFilters"): void;
}>();

const { isSmall } = useBreakpoints();
const { t } = useI18n();
const slots = useSlots();
</script>

<template>
  <div class="grid gap-2">
    <div class="flex flex-nowrap items-end gap-2">
      <div v-if="!isSmall" class="flex items-end gap-2">
        <slot name="primaryFilters"></slot>
      </div>
      <div class="flex flex-1 items-center gap-2">
        <SearchInput v-model="searchQuery" class="flex-1" />
        <GButton
          v-if="slots.secondaryFilters"
          variant="tertiary"
          @click="emit('toggleSecondaryFilters')"
        >
          {{
            props.isSecondaryFiltersVisible
              ? t("common.less_filters")
              : t("common.more_filters")
          }}
        </GButton>
        <GButton v-else variant="secondary" @click="emit('reset')">
          {{ t("common.reset") }}
        </GButton>
      </div>
    </div>
    <div v-if="isSmall">
      <slot name="primaryFilters"></slot>
    </div>
    <div
      v-if="props.isSecondaryFiltersVisible"
      class="rounded-lg border px-6 py-4"
    >
      <div class="flex justify-between">
        <span>{{ t("common.filters") }}</span>
        <GButton variant="secondary" @click="emit('reset')">
          {{ t("common.reset") }}
        </GButton>
      </div>
      <div class="grid grid-flow-col items-end gap-2">
        <slot name="secondaryFilters"> </slot>
      </div>
    </div>
  </div>
</template>
