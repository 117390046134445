import type { Lesson } from "@/types/lesson";
import { defineStore } from "pinia";
import { useCourseBffFetch } from "@/composables/useFetch";

export const useLessonsStore = defineStore("lessons", () => {
  const {
    data: lessons,
    isFetching,
    error,
  } = useCourseBffFetch("/api/lessons/list").json<Lesson[]>();

  function generateContainerId(ghccId: string) {
    return lessons.value.find((lesson) => lesson.ghccId === ghccId)
      ?.containerId;
  }

  function getLessonImageUrl(ghccId: string) {
    return lessons.value.find((lesson) => lesson.ghccId === ghccId)?.imageUrl;
  }

  function getLessonRegion(ghccId: string, language: string) {
    const foundLesson = lessons.value.find(
      (lesson) => lesson.ghccId === ghccId,
    );

    if (!foundLesson) return language;

    return foundLesson.language.split("-")[1];
  }

  return {
    lessons,
    isFetching,
    error,
    generateContainerId,
    getLessonImageUrl,
    getLessonRegion,
  };
});
