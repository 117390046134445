import { formatISO, parseISO } from "date-fns";
import { ref, watch, type Ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const getCastedValue = (initialFilterValue, queryValue) => {
  if (initialFilterValue instanceof Date) {
    return parseISO(queryValue);
  } else if (typeof queryValue === "string" && !isNaN(parseFloat(queryValue))) {
    return Number(queryValue);
  }
  return queryValue;
};

export function usePersistedFilter<T = Record<string, unknown>>(
  initialFilters: T,
): { filters: Ref<T>; resetFilters: () => void } {
  const route = useRoute();
  const router = useRouter();
  const filters = ref<T>({ ...initialFilters }) as Ref<T>;

  const resetFilters = () => {
    filters.value = { ...initialFilters };
  };

  // Parse query params as filter values
  Object.entries(initialFilters).forEach(([key, value]) => {
    const queryValue = route.query[key];
    if (queryValue) {
      if (Array.isArray(value)) {
        filters.value[key] = (queryValue as string)
          .split(",")
          .map((item) => getCastedValue(value[0], item));
      } else filters.value[key] = getCastedValue(value, queryValue);
    }
  });

  // Watch for changes in filters and update query params
  watch(
    filters,
    (newFilter) => {
      const existingQuery = { ...route.query };
      const query: Record<string, string> = {};
      Object.entries(newFilter).forEach(([key, value]) => {
        if (value !== null && value !== initialFilters[key]) {
          if (Array.isArray(value)) {
            if (value.length > 0 && value[0] !== null) {
              query[key] = value
                .map((item) => (item instanceof Date ? formatISO(item) : item))
                .join(",");
            }
          } else {
            query[key] =
              value instanceof Date ? formatISO(value) : String(value);
          }
        }
      });

      const mergedQuery = { ...existingQuery, ...query };

      Object.entries(newFilter).forEach(([key, value]) => {
        if (
          value === null ||
          value === initialFilters[key] ||
          (Array.isArray(value) && (value.length === 0 || value[0] === null))
        ) {
          delete mergedQuery[key];
        }
      });

      router.replace({ query: mergedQuery });
    },
    { deep: true },
  );

  return {
    filters,
    resetFilters,
  };
}
