import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatISO } from "date-fns";

import { useFetch, useReportingServiceFetch } from "@/composables";

import type {
  ColumnAccessor,
  DetailedUserTableRow,
  DetailedUserReport,
  FetchDetailedUserReportPayload,
  Filters,
} from "./types";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { createTableDef } from "@/patterns";
import { useFlagStore } from "@/stores";
import { getPayloadTags } from "../utils";

export const useDetailedUserReport = (filters: Ref<Filters>) => {
  const payload = computed(() => {
    return {
      DateFrom: formatISO(filters.value.date[0]),
      DateTo: formatISO(filters.value.date[1]),
      SearchFor: filters.value.search,
      Tags: getPayloadTags(filters.value.teams),
      StatusId: -1,
      isOptingInNewDashboard: true,
    };
  });
  const { t } = useI18n();
  const flagStore = useFlagStore();

  const endpoint = computed(() =>
    flagStore.consumeMigratedStudentProgressLogReportFlag
      ? "api/student-progress-log"
      : "api/ReportStudentProgressLog",
  );

  const handler = computed(() =>
    flagStore.consumeMigratedStudentProgressLogReportFlag
      ? useReportingServiceFetch
      : useFetch,
  );

  const fetchDetailedUserReport = (
    payload: Ref<FetchDetailedUserReportPayload>,
  ) => {
    const {
      isFetching: isFetchingReport,
      error,
      data: milestones,
    } = handler
      .value(endpoint, { refetch: true })
      .post(payload)
      .json<DetailedUserReport[]>();

    return { isFetchingReport, error, milestones };
  };

  const { isFetchingReport, error, milestones } =
    fetchDetailedUserReport(payload);

  const hasForeignUsername = computed(
    () =>
      flagStore.reportsForeignUsernameFlag &&
      milestones.value?.some((milestone) => milestone.ForeignUsername),
  );

  const tableDefinition = computed(() =>
    createTableDef<ColumnAccessor>([
      {
        id: "name",
        headerLabel: t("common.name"),
        accessor: "name",
        isGloballyFilterable: true,
        smallScreenPosition: "center",
      },
      {
        id: "courseName",
        headerLabel: t("reports.tbl_col_course_title"),
        accessor: "courseName",
      },
      {
        id: "lessonName",
        headerLabel: t("detailedUser.tbl_col_lesson"),
        accessor: "lessonName",
      },
      {
        id: "activity",
        headerLabel: t("detailedUser.tbl_col_activity"),
        accessor: "activity",
      },
      {
        id: "progress",
        headerLabel: t("reports.tbl_col_progress"),
        accessor: "progress",
      },
      {
        id: "sessionTime",
        headerLabel: t("detailedUser.tbl_col_session_time"),
        accessor: "sessionTime",
      },
      {
        id: "date",
        headerLabel: t("reports.tbl_col_date"),
        accessor: "date",
      },
    ]),
  );

  return {
    isFetching: isFetchingReport,
    error,
    data: computed(() => mapDetailedUsersDataToTableData(milestones.value)),
    tableDefinition,
    hasForeignUsername,
  };
};

const mapDetailedUsersDataToTableData = (
  report: DetailedUserReport[] | null,
): DetailedUserTableRow[] => {
  if (!report) return [];
  return report.map((user) => ({
    courseName: user.CourseTitle || "-",
    email: user.Email,
    activity: user.PlayerProperties?.title || "-",
    lessonName: user.ModuleProperties?.title || "-",
    name: `${user.LastName}, ${user.FirstName} ${user.MidName}`,
    progress: user.Score,
    sessionTime: formatSecondsToHHMMSS(user.SessionTime),
    date: new Date(user.LogDate),
    foreignUsername: user.ForeignUsername,
  }));
};
