<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { GEmptyState, GSpinner } from "@/components";
import { useTeamsStore } from "@/stores";
import errorPageImage from "@/assets/images/error-page-image.svg?url";
import { SearchInput } from "@/patterns";

const { t } = useI18n();
const teamsStore = useTeamsStore();
const { teamsList, isFetchingTeams, teamsError, newTeam } =
  storeToRefs(teamsStore);
const searchQuery = ref("");

const selectedTeams = defineModel<number[]>({ default: [] });
watch(newTeam, () => {
  if (newTeam.value) {
    selectedTeams.value.push(newTeam.value.Id);
  }
});

const filteredTeams = computed(() => {
  return teamsList.value?.filter((team) =>
    team.Name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});
</script>
<template>
  <p class="text-grey-80 md:mb-6">
    {{ t("organisation.modal_add_to_team_description") }}
  </p>
  <SearchInput v-model="searchQuery" name="team-name" :debounce="0" />
  <div class="flex items-center mt-4 mb-2">
    <span id="teams-list-label">{{
      t("organisation.teams_selected", { n: selectedTeams.length })
    }}</span>
  </div>
  <ul
    v-if="teamsList"
    aria-labelledby="teams-list-label"
    class="block h-[40vh] overflow-y-auto"
  >
    <li
      v-for="team in filteredTeams"
      :key="team.Id"
      class="flex items-center gap-2 rounded-lg p-4 hover:bg-slate-100"
    >
      <input
        type="checkbox"
        class="checkbox checkbox-primary"
        :value="team.Id"
        v-model="selectedTeams"
      />{{ team.Name }}
      <span class="ml-auto">{{
        t("organisation.team_members_count", { count: team.ActiveUserCount })
      }}</span>
    </li>
  </ul>
  <GSpinner v-if="isFetchingTeams" />
  <GEmptyState
    v-if="teamsError"
    class="mx-auto"
    :title="teamsError"
    :description="teamsError"
    :imageUrl="errorPageImage"
  ></GEmptyState>
</template>
