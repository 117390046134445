<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useMixpanel } from "@/composables";
import { useUserActionsStore } from "../store";
import type { BulkUserActionResponse } from "../types";
import AnonymiseConfirmation from "./AnonymiseConfirmation.vue";
import BaseUserActionModal from "./BaseUserActionModal.vue";

const confirmationText = ref("");
const { track } = useMixpanel();
const { closeModal, performUserAction } = useUserActionsStore();
const {
  activeAction,
  selectedUsers,
  activeRow,
  selectedUserIds,
  allowedIdsForAction,
  excludedFromActionCount,
} = storeToRefs(useUserActionsStore());
const { t } = useI18n();

const anonymiseUsersByIds = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/AnonymizeUser/ByUserIds",
      method: "POST",
      body: {
        personIds: allowedIdsForAction.value,
      },
    },
    successMessage: t("organisation.generic_action_success", {
      n: allowedIdsForAction.value.length,
      action: t("organisation.action.anonymise"),
    }),
    errorMessage: t(
      "organisation.generic_action_error",
      { action: t("organisation.action.anonymise") },
      { plural: selectedUserIds.value.length },
    ),
  });

  track("userAnonymize", {
    user_count: selectedUserIds.value.length,
    action_mode: activeRow.value ? "single" : "bulk_selection",
  });
};
</script>
<template>
  <BaseUserActionModal
    actionId="anonymiseUser"
    :isOpen="activeAction === 'anonymiseUser'"
    :title="t('organisation.anonymise_user(s)', selectedUsers.length || 1)"
    :description="t('organisation.modal_anonymise_description')"
    :excludedCount="excludedFromActionCount"
    :confirmButtonProps="{
      text: t('organisation.anonymise'),
      variant: 'danger',
      disabled:
        confirmationText.toLocaleLowerCase() !==
        t('organisation.anonymise').toLocaleLowerCase(),
    }"
    @close="closeModal()"
    @confirm="anonymiseUsersByIds()"
    data-testId="anonymise-modal"
    class="w-full max-w-[480px]"
  >
    <AnonymiseConfirmation v-model="confirmationText" />
  </BaseUserActionModal>
</template>
