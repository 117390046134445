import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import { useFetch } from "@/composables";
import type { TableColumn } from "@/patterns";
import type { GoodScanCourse } from "@gh-dashboard/types";
import { multiSelectLanguageFilter } from "@/utils/filterFns";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";

type ColumnAccessor = "name" | "language";

export type GoodScanCourseTableDataRow = {
  id: number;
  name: string;
  language: string;
  lti11Link: string;
  lti13Link: string;
  courseCode: string;
};

export const useGoodScanTab = () => {
  const { t } = useI18n();
  const { isFetching, data, error } =
    useFetch("/api/goodscanlist").json<GoodScanCourse[]>();

  const goodScanTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "name",
      headerLabel: t("common.name"),
      accessor: "name",
      smallScreenPosition: "center",
    },
    {
      id: "language",
      headerLabel: t("common.language"),
      accessor: "language",
      filterFnName: multiSelectLanguageFilter,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const goodScanList: Ref<GoodScanCourseTableDataRow[]> = computed(
    () =>
      data.value?.map((course) => ({
        id: course.CourseId,
        name: course.CourseName,
        language: course.Lang.toUpperCase(),
        lti11Link: course.LtiLink,
        lti13Link: course.Lti13Link,
        courseCode: course.CourseCode,
      })) ?? [],
  );

  const trackExportGoodscan = (
    selectedActivities: GoodScanCourseTableDataRow[],
  ) => {
    trackMixPanelEvent("goodscanExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.name,
      })),
      context: "Connect Page",
    });
  };

  return {
    isFetching,
    error,
    goodScanList,
    goodScanTableDefinition,
    trackExportGoodscan,
  };
};
