import {
  trackMixPanelEvent,
  type MixpanelEventName,
} from "@/services/analytics/mixpanel";
import { useUserStore } from "@/stores";
import type mixpanel from "mixpanel-browser";
import { useRoute } from "vue-router";

type TrackEventParams = Parameters<typeof mixpanel.track>;

export const useMixpanel = () => {
  const { user } = useUserStore();
  const route = useRoute();

  function track(
    eventName: MixpanelEventName,
    extraProperties?: TrackEventParams[1],
  ) {
    return trackMixPanelEvent(eventName, {
      companyId: user?.ExternalCompanyReference,
      distinct_id: user?.ExternalPersonReference,
      pathname: route.path,
      pagename: route.name,
      dashboard_environment: "new",
      ...extraProperties,
    });
  }

  return { track };
};
