import { useRoute } from "vue-router";
import {
  useFetch,
  usePersistedFilter,
  useQueryString,
  useReportingServiceFetch,
} from "@/composables";
import type { UserReportDetails } from "./types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { differenceInDays, formatISO, getMonth, getTime } from "date-fns";
import { reportInitialDateRange } from "../utils";
import { useFlagStore } from "@/stores";

export const useUserReportDetails = () => {
  const flagStore = useFlagStore();
  const { params } = useRoute();
  const { t } = useI18n();
  const { queryString } = useQueryString();
  const { filters } = usePersistedFilter<{ date: [Date, Date] }>({
    date: reportInitialDateRange,
  });
  const payload = computed(() => ({
    PersonId: Number(params.studentId),
    SearchFor: "",
    DateFrom: formatISO(filters.value.date[0]),
    DateTo: formatISO(filters.value.date[1]),
  }));

  const fetchConfig = {
    refetch: true,
  };
  const endpoint = computed(() =>
    flagStore.consumeMigratedStudentDetailsReportFlag
      ? "api/student-details"
      : "api/ReportStudentDetail",
  );

  const handler = computed(() =>
    flagStore.consumeMigratedStudentDetailsReportFlag
      ? useReportingServiceFetch
      : useFetch,
  );

  const {
    data: userDetails,
    isFetching,
    error,
  } = handler
    .value(endpoint, fetchConfig)
    .post(payload)
    .json<UserReportDetails>();

  const statsData = computed(() => {
    if (!userDetails.value) return [];
    return [
      {
        icon: "clock",
        data: [
          {
            label: t("reports.tbl_col_study_time_hhmmss"),
            value: formatSecondsToHHMMSS(userDetails.value.ContentSeconds),
          },
        ],
      },
      {
        icon: "fileCertificate",
        data: [
          {
            label: t("reports.tbl_col_course_started"),
            value: userDetails.value.CoursesStarted.toString(10),
          },
          {
            label: t("reports.tbl_col_course_completed"),
            value: userDetails.value.CoursesCompleted.toString(10),
          },
        ],
      },
      {
        icon: "fileCertificate",
        data: [
          {
            label: t("reports.tbl_col_test_passed"),
            value: userDetails.value.TestsPassed.toString(10),
          },
        ],
      },
    ];
  });

  const formattedActivityVisitsData = computed(() => {
    if (!userDetails.value) {
      return { labels: [], data: [] };
    }

    // if less then 60 days, show days individually
    if (differenceInDays(filters.value.date[1], filters.value.date[0]) < 60) {
      return {
        labels: userDetails.value.ModuleCount.map(({ dd, mm, yyyy }) =>
          getTime(new Date(`${yyyy}-${mm}-${dd}`)),
        ),
        data: userDetails.value.ModuleCount.map(
          (dataPoint) => dataPoint.modules,
        ),
      };
    }

    const groupedData = groupCountPerMonthAndYear(
      userDetails.value.ModuleCount.map(({ dd, mm, yyyy, modules }) => ({
        date: new Date(`${yyyy}-${mm}-${dd}`),
        count: modules,
      })),
    );
    return {
      labels: Object.keys(groupedData).map((key) => getTime(new Date(key))),
      data: Object.values(groupedData),
    };
  });

  return {
    userDetails,
    isFetching,
    error,
    statsData,
    formattedActivityVisitsData,
    filters,
    queryString,
  };
};

const groupCountPerMonthAndYear = (
  data: Array<{ date: Date; count: number }>,
): Record<string, number> => {
  const result: Record<string, number> = {};
  for (const dataPoint of data) {
    const date = dataPoint.date;
    const month = getMonth(date) + 1;
    const year = date.getFullYear();
    const key = `${year}-${month}`;
    if (result[key]) {
      result[key] += dataPoint.count;
    } else {
      result[key] = dataPoint.count;
    }
  }
  return result;
};
