<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { z } from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";

import { TextInput, GRange } from "@/components";
import ConnectionCard from "./ConnectionCard.vue";
import type { ScormKey } from "./ScormTab.vue";
import { formatPascalCaseToSnake } from "@/utils/misc";

const { t } = useI18n();
const schemaDefinition = z.object({
  Domain: z
    .string()
    .min(1, t("validationMessages.required"))
    .url(t("validationMessages.url")),
  TestWeighing: z.number().min(0).max(100),
});

type SchemaDefinition = z.infer<typeof schemaDefinition>;

const props = defineProps<{
  connectionKey: ScormKey;
  isInEditMode: boolean;
}>();

const emit = defineEmits<{
  (e: "save", value: ScormKey): void;
  (e: "delete", value: number): void;
  (e: "edit", key: ScormKey): void;
  (e: "cancel"): void;
}>();

const validationSchema = toTypedSchema(schemaDefinition);

const { handleSubmit, isSubmitting, setErrors, values, setFieldValue } =
  useForm<SchemaDefinition>({
    validationSchema: validationSchema,
    initialValues: props.connectionKey,
  });

const handleAddScormKey = handleSubmit(
  (data) => {
    emit("save", {
      ...props.connectionKey,
      ...data,
    });
  },
  ({ errors }) => {
    setErrors(errors);
  },
);

const scormFields: ReadonlyArray<keyof SchemaDefinition> = [
  "Domain",
  "TestWeighing",
];
</script>
<template>
  <ConnectionCard
    :title="props.connectionKey.Domain"
    :isInEditMode="props.isInEditMode"
    :isSubmitting="isSubmitting"
    @edit="emit('edit', connectionKey)"
    @cancel="emit('cancel')"
    @delete="emit('delete', connectionKey.Id)"
    @save="handleAddScormKey"
  >
    <div class="flex flex-col gap-2">
      <TextInput
        v-for="fieldName in scormFields.filter((f) => f !== 'TestWeighing')"
        :key="fieldName"
        :name="fieldName"
        :readonly="!props.isInEditMode"
        :label="t(`connectionMethods.${formatPascalCaseToSnake(fieldName)}`)"
        :modelValue="values[fieldName]"
        @update:modelValue="setFieldValue(fieldName, $event)"
      />
      <GRange
        :id="`${props.connectionKey.Id}-range`"
        :label="t('connectionMethods.weight')"
        :weight-labels="[
          t('connectionMethods.test'),
          t('connectionMethods.lessons'),
        ]"
        :modelValue="values['TestWeighing']"
        @update:modelValue="setFieldValue('TestWeighing', $event)"
        :disabled="!props.isInEditMode"
      />
    </div>
  </ConnectionCard>
</template>
