<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import {
  flip,
  offset as offsetModifier,
  arrow,
  autoUpdate,
  computePosition,
  type Placement,
} from "@floating-ui/dom";

import InfoIcon from "@/assets/images/icons/info.svg";

const props = withDefaults(
  defineProps<{
    placement?: Placement;
    isOpen?: boolean;
    hasArrow?: boolean;
    offset?: [number, number];
    arrowPlacement?: "start" | "end";
    arrowOffset?: number;
    displayDelay?: number;
  }>(),
  {
    // Vue defaults isOpen to false, but we want it to be undefined if not explicitly set
    isOpen: undefined,
    hasArrow: true,
    offset: () => [8, 8],
    arrowPlacement: "start",
    arrowOffset: 16,
    displayDelay: 0,
  },
);

let cleanup = () => {};
let showTimeoutId: ReturnType<typeof setTimeout> | null = null;
const tooltipId = `tooltip-${Math.floor(Math.random() * 1000)}`;

const referenceRef = ref<HTMLDivElement>();
const floatingRef = ref<HTMLDivElement>();
const arrowRef = ref<HTMLDivElement>();
const isUncontrolledOpen = ref(false);

const isInControlledMode = computed(() => props.isOpen !== undefined);

const handleOpen = () => {
  if (!isInControlledMode.value) {
    if (props.displayDelay > 0) {
      showTimeoutId = setTimeout(() => {
        isUncontrolledOpen.value = true;
        showTimeoutId = null;
      }, props.displayDelay);
    } else {
      isUncontrolledOpen.value = true;
    }
  }
};

const handleClose = () => {
  if (!isInControlledMode.value) {
    if (showTimeoutId) {
      clearTimeout(showTimeoutId);
      showTimeoutId = null;
    }
    isUncontrolledOpen.value = false;
  }
};

onMounted(() => {
  if (referenceRef.value && floatingRef.value && arrowRef.value) {
    const referenceEl = referenceRef.value;
    const floatingEl = floatingRef.value;

    cleanup = autoUpdate(referenceEl, floatingEl, async () => {
      const middleware = [
        offsetModifier({
          mainAxis: props.offset[0],
          alignmentAxis: props.offset[1],
        }),
        flip(),
      ];
      if (arrowRef.value)
        middleware.push(
          arrow({ element: arrowRef.value, padding: props.arrowOffset }),
        );
      const { x, y, middlewareData, placement } = await computePosition(
        referenceEl,
        floatingEl,
        {
          placement: props.placement ?? "top",
          middleware,
        },
      );

      Object.assign(floatingEl.style, {
        left: x != null ? `${x}px` : "",
        top: y != null ? `${y}px` : "",
      });

      const side = placement.split("-")[0];

      const staticSide =
        {
          top: "bottom",
          right: "left",
          bottom: "top",
          left: "right",
        }[side] || "top";

      const arrowPlacement = {
        start: "left",
        end: "right",
      }[props.arrowPlacement];

      if (arrowRef.value) {
        const arrowX = middlewareData.arrow?.x;
        const arrowY = middlewareData.arrow?.y;

        Object.assign(arrowRef.value.style, {
          [arrowPlacement]: arrowX ? `${arrowX}px` : "",
          top: arrowY ? `${arrowY}px` : "",
          [staticSide]: `${-arrowRef.value.offsetWidth / 2}px`,
        });
      }
    });
  }
});

onUnmounted(() => {
  cleanup();
  window.clearTimeout(showTimeoutId);
});
</script>
<template>
  <div
    :aria-describedby="tooltipId"
    ref="referenceRef"
    tabindex="0"
    @mouseenter="handleOpen()"
    @mouseleave="handleClose()"
    @focus="handleOpen()"
    @blur="handleClose()"
  >
    <slot name="reference">
      <span>
        <InfoIcon class="ml-1" />
      </span>
    </slot>
  </div>
  <div
    :id="tooltipId"
    role="tooltip"
    ref="floatingRef"
    class="absolute z-10 rounded"
    :class="{
      hidden: props.isOpen === undefined ? !isUncontrolledOpen : !props.isOpen,
    }"
    v-bind="$attrs"
  >
    <slot name="content"></slot>
    <div
      v-if="props.hasArrow"
      ref="arrowRef"
      class="absolute h-3 w-3 rotate-45 bg-inherit"
    ></div>
  </div>
</template>
