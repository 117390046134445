<script setup lang="ts">
import { ref, computed, unref, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { Listbox, ListboxLabel, ListboxOptions } from "@headlessui/vue";
import { useFloating, flip, offset, shift, size } from "@floating-ui/vue";
import DropdownButtonTrigger from "./DropdownButtonTrigger.vue";
import GDropdownOption from "./GDropdownOption.vue";
import type { DropdownProps, ValueType } from "./types";

import SearchIcon from "@/assets/images/icons/search.svg";

const props = defineProps<DropdownProps>();

const emit = defineEmits<{
  (event: "change", value: ValueType | ValueType[]): void;
  (event: "close"): void;
  (event: "open"): void;
}>();

const searchQuery = ref("");
const dropdown = ref<InstanceType<typeof Listbox> | null>(null);
const { t } = useI18n();

const filteredOptions = computed(() => {
  const unreffedOptions = unref(props.options);
  if (!searchQuery.value) return unreffedOptions;
  return unreffedOptions.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

const optionsWithDefault = computed(() => {
  if (!props.hasDefault) return filteredOptions.value;
  return [
    props.isMultiChoice
      ? {
          label: t("common.all"),
          value: null,
        }
      : {
          label: t("common.select_item"),
          value: null,
        },
    ...filteredOptions.value,
  ];
});

const singleSelectedOptionLabel = computed(() => {
  if (Array.isArray(props.value)) return null;
  return (
    optionsWithDefault.value.find((option) => option.value === props.value)
      ?.label ?? ""
  );
});

const handleValueChosen = (value: ValueType | ValueType[]) => {
  if (Array.isArray(value)) {
    if (value.length === 2 && value[0] === null) emit("change", value.slice(1));
    else if (value.length === 0 || value.includes(null)) emit("change", [null]);
    else emit("change", value);
  } else emit("change", value);
};

const dropdownTrigger = ref(null);
const dropdownOptions = ref(null);

const { floatingStyles } = useFloating(dropdownTrigger, dropdownOptions, {
  placement: "bottom-start",
  strategy: "absolute",
  middleware: [
    offset(4),
    flip(),
    shift({ padding: 5 }),
    size({
      apply({ rects }) {
        if (dropdownOptions.value) {
          Object.assign(dropdownOptions.value.el.style, {
            width: `${rects.reference.width}px`,
          });
        }
      },
    }),
  ],
});

const setTriggerRef = (ref: HTMLElement) => {
  dropdownTrigger.value = ref;
};

onMounted(() => {
  const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "data-headlessui-state"
      ) {
        if (
          (mutation.target as HTMLElement).dataset.headlessuiState === "open"
        ) {
          emit("open");
        } else {
          emit("close");
        }
      }
    });
  });

  mutationObserver.observe(
    (dropdown.value?.$el as HTMLElement).parentNode.firstElementChild,
    {
      attributes: true,
      attributeFilter: ["data-headlessui-state"],
    },
  );
});
</script>

<template>
  <Listbox
    ref="dropdown"
    :modelValue="value"
    as="div"
    @update:modelValue="handleValueChosen"
    v-slot="{ open }"
    :multiple="isMultiChoice"
    :disabled="isDisabled"
  >
    <ListboxLabel
      v-if="props.label"
      class="mb-2 inline-block text-sm font-medium text-black text-nowrap"
      data-testid="dropdown-label"
    >
      {{ props.label }}
    </ListboxLabel>
    <!-- 
      we can benefit from function refs here, the ref is decided by the user in case of a custom trigger
      we pass a function to the slot that sets the ref to the trigger element.
      In case of the default trigger, we pass the ref to the trigger element directly.
     -->
    <slot name="trigger" :setElementRef="setTriggerRef" :isMenuOpen="open">
      <DropdownButtonTrigger
        ref="dropdownTrigger"
        :isMenuOpen="open"
        :value="props.value"
        :label="props.label"
        :disabled="isDisabled"
        :singleSelectedOptionLabel="singleSelectedOptionLabel"
        data-testid="dropdown-trigger"
      />
    </slot>

    <ListboxOptions
      static
      v-if="open"
      ref="dropdownOptions"
      class="z-20 mt-1 max-h-64 min-w-max overflow-y-auto rounded-md border border-grey-10 bg-white [box-shadow:0px_4px_8px_0px_rgba(0,0,0,0.24)] data-[headlessui-state=open]:focus:outline-primary"
      :style="floatingStyles"
    >
      <div
        v-if="props.searchable"
        class="flex items-center border-b border-grey-10 focus-within:border-primary"
      >
        <div class="grid h-full place-content-center pl-4">
          <SearchIcon class="h-4 w-4" />
        </div>
        <input
          v-model="searchQuery"
          class="w-full rounded-t-md py-3.5 pl-2 pr-4 focus-visible:outline-none"
          name="Search options"
          :placeholder="`${t('common.search')}...`"
        />
      </div>
      <GDropdownOption
        v-for="(option, index) in optionsWithDefault"
        :key="index"
        :option="option"
        :type="isMultiChoice ? 'checkbox' : 'default'"
      >
      </GDropdownOption>
    </ListboxOptions>
  </Listbox>
</template>
