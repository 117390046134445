<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { format } from "date-fns";

import {
  PageLayout,
  GStateHandlerWrapper,
  GNameCard,
  ActionMenu,
  GButton,
  GDatePicker,
} from "@/components";
import { DashboardSwitchBanner } from "@/patterns";
import {
  GDropdownButtonTrigger,
  GDropdownIconButtonTrigger,
} from "@/components/GDropdown";
import StatusBadge from "./components/StatusBadge.vue";
import ModalRevokeInvitation from "./components/ModalRevokeInvitation.vue";
import ModalResendInvitation from "./components/ModalResendInvitation.vue";
import ModalResetPassword from "./components/ModalResetPassword.vue";
import ModalAddToTeam from "./components/ModalAddToTeams/ModalAddToTeams.vue";
import ModalAssignRole from "./components/ModalAssignRole.vue";
import ModalAnonymise from "./components/ModalAnonymise.vue";
import ModalBlock from "./components/ModalBlock.vue";
import ModalExport from "./components/ModalExport.vue";
import { BulkAddToTeamsModal } from "./components/BulkAddToTeamsModal";
import { BulkAnonymiseModal } from "./components/BulkAnonymiseModal";
import { BulkRevokeInvitationModal } from "./components/BulkRevokeInvitationModal";
import { BulkBlockModal } from "./components/BulkBlockModal";
import { BulkInviteUsersModal } from "./components/BulkInviteUsersModal";
import {
  FilterPanel,
  RolesSelect,
  TeamsSelect,
  UserStatusSelect,
  GTable,
  ListCell,
  type TableColumn,
} from "@/patterns";
import type { ColumnAccessor } from "./types";
import type { UserStatusKey } from "@/constants/user";
import useUserActionsStore from "./store";
import { useUserStore } from "@/stores";

import PlusIcon from "@/assets/images/icons/plus.svg";
import EllipsisVerticalIcon from "@/assets/images/icons/ellipsis-vertical.svg";

const userActionsStore = useUserActionsStore();
const userStore = useUserStore();
const { t } = useI18n();
const {
  filters,
  userList,
  usersListResponse,
  isFetching,
  usersListError,
  selectedUsers,
  activeRow,
  activeAction,
  userBulkActions,
  actionsForSelectedRows,
  actionsForRow,
} = storeToRefs(userActionsStore);
const { roleCodeToLabel } = storeToRefs(userStore);

const isSecondaryFiltersVisible = ref(false);

const availableStatuses = computed(
  () =>
    usersListResponse.value &&
    (Object.fromEntries(
      Object.entries(usersListResponse.value?.Metadata.StatusCount).map(
        ([k, v]) => [k.toLowerCase(), v],
      ),
    ) as Record<UserStatusKey, number>),
);

const defaultSort = [{ desc: false, id: "name" }];

const tableDefinition: TableColumn<ColumnAccessor>[] = [
  {
    id: "name",
    headerLabel: t("common.name"),
    accessor: "name",
    smallScreenPosition: "center",
  },
  {
    id: "roles",
    headerLabel: t("organisation.role", 2),
    accessor: "roles",
  },
  {
    id: "teams",
    headerLabel: t("reports.teams"),
    accessor: "teams",
  },
  {
    id: "status",
    headerLabel: t("common.status"),
    accessor: "status",
  },
  {
    id: "activationDate",
    headerLabel: t("organisation.activation_date"),
    accessor: "activationDate",
  },
  {
    id: "actions",
    headerLabel: "",
    smallScreenPosition: "last",
  },
];

const checkForActiveAction = () => {
  if (activeAction.value !== null) {
    return;
  }
  activeRow.value = null;
};
</script>
<template>
  <div class="grid grid-rows-[max-content_1fr]">
    <DashboardSwitchBanner :label="t('common.toggle_bar_label')" opt="in" />
    <PageLayout :title="t('dashboardMenu.organisation_users')" heading="Users">
      <template #primaryAction>
        <div class="flex gap-2">
          <ActionMenu :options="userBulkActions" :activeAction="activeAction">
            <template #trigger="{ setElementRef, isMenuOpen }">
              <GDropdownButtonTrigger
                singleSelectedOptionLabel="Bulk Actions"
                :isMenuOpen="isMenuOpen"
                :ref="setElementRef"
              />
            </template>
          </ActionMenu>
          <GButton @click="activeAction = 'bulkInviteUser'" class="h-auto">
            <template #suffixIcon>
              <PlusIcon />
            </template>
            {{ t("organisation.invite_users") }}
          </GButton>
        </div>
      </template>

      <FilterPanel
        v-model:search="filters.search"
        :isSecondaryFiltersVisible="isSecondaryFiltersVisible"
        @reset="userActionsStore.resetFilters"
        @toggleSecondaryFilters="
          isSecondaryFiltersVisible = !isSecondaryFiltersVisible
        "
      >
        <template #primaryFilters>
          <UserStatusSelect
            :isButtonSelect="true"
            v-if="availableStatuses"
            :statusCounts="availableStatuses"
            v-model="filters.status"
          />
        </template>
        <template #secondaryFilters>
          <TeamsSelect v-model="filters.teams" />
          <RolesSelect v-model="filters.roles" />
          <GDatePicker
            v-model="filters.activationDate"
            :label="t('organisation.activation_date')"
          />
        </template>
      </FilterPanel>
      <GStateHandlerWrapper :isLoading="isFetching" :error="usersListError">
        <template v-if="userList">
          <GTable
            :data="userList"
            :columns="tableDefinition"
            :selectedList="selectedUsers"
            @selectedListChange="
              (list) => selectedUsers.splice(0, selectedUsers.length, ...list)
            "
            :defaultSort="defaultSort"
            :selectable="true"
            :actions="actionsForSelectedRows"
          >
            <template v-slot:name="{ row }">
              <GNameCard :name="row.name" :fuid="row.fuid" :email="row.email" />
            </template>
            <template v-slot:status="{ value }">
              <StatusBadge :status="value as number" />
            </template>
            <template v-slot:roles="{ value }">
              <ListCell
                :list="
                  (value as string[]).map(
                    (roleCode) => roleCodeToLabel[roleCode],
                  )
                "
              />
            </template>
            <template v-slot:teams="{ value }">
              <ListCell
                :list="
                  (value as Array<{ Id: number; Name: string }>).map(
                    (team) => team.Name,
                  )
                "
              />
            </template>
            <template v-slot:activationDate="{ value }">
              {{ value ? format(value as Date, "PPP") : "-" }}
            </template>
            <template v-slot:actions="{ row }">
              <ActionMenu
                @open="activeRow = row"
                @close="checkForActiveAction"
                :options="actionsForRow"
                :activeAction="activeAction"
              >
                <template #trigger="{ setElementRef }">
                  <GDropdownIconButtonTrigger
                    :icon="EllipsisVerticalIcon"
                    :ref="setElementRef"
                  />
                </template>
              </ActionMenu>
            </template>
          </GTable>
        </template>
        <ModalRevokeInvitation />
        <ModalResendInvitation />
        <ModalResetPassword />
        <ModalAddToTeam />
        <ModalAssignRole />
        <ModalAnonymise />
        <ModalBlock />
        <ModalExport />
        <BulkAnonymiseModal />
        <BulkAddToTeamsModal />
        <BulkRevokeInvitationModal />
        <BulkBlockModal />
        <BulkInviteUsersModal />
      </GStateHandlerWrapper>
    </PageLayout>
  </div>
</template>
