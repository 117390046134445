<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import type { LanguageCode } from "@/utils/i18n";
import { useLocale } from "@/composables";
import useUserActionsStore from "../../store";
import { GButton, GCheckbox, TextArea } from "@/components";
import LanguageSelect from "@/patterns/LanguageSelect/LanguageSelect.vue";
import type { BulkUserActionResponse } from "../../types";
import { useMixpanel } from "@/composables/useMixpanel";

const { t } = useI18n();
const { locale } = useLocale();

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
    additionalProps: {
      withoutHint: true,
      placeholder: t("organisation.bulk_invite_users_placeholder"),
    },
  },
];

const invitedEmails = ref<{ emails: string[] }>({
  emails: [],
});
const isSendingInviteMessage = ref(true);
const inviteMessage = ref("");
const languageCode = ref(locale.value);

const { performUserAction } = useUserActionsStore();
const { track } = useMixpanel();
const { activeAction, isActionRequestPending } = storeToRefs(
  useUserActionsStore(),
);

const inviteUsers = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/ActivityQueue",
      method: "POST",
      body: {
        targetAudience: invitedEmails.value.emails.join(";"),
        languageCode: languageCode.value,
        sendEmail: isSendingInviteMessage.value,
        invitationTxt: isSendingInviteMessage.value ? inviteMessage.value : "",
        Tags: "",
      },
    },
    successMessage: t("organisation.generic_action_success", {
      n: invitedEmails.value.emails.length,
      action: t("organisation.action.invite"),
    }),
    errorMessage: t("organisation.generic_action_error", {
      n: invitedEmails.value.emails.length,
      action: t("organisation.action.invite"),
    }),
  });

  track("userInvite", {
    user_count: invitedEmails.value.emails.length,
    action_mode: "bulk_email",
  });
};

const handleClose = () => {
  activeAction.value = null;
  invitedEmails.value = {
    emails: [],
  };
};
</script>
<template>
  <BulkActionsModal
    :isOpen="activeAction === 'bulkInviteUser'"
    :data="invitedEmails"
    :steps="steps"
    :title="t('organisation.invite_users')"
    :subtitle="t('organisation.modal_bulk_invite_users_subtitle')"
    :confirmButtonProps="{
      label: t('organisation.block'),
      variant: 'danger',
      isLoading: isActionRequestPending,
    }"
    @close="handleClose"
  >
    <div class="mt-4 flex items-center gap-2">
      <GCheckbox id="send-invite-message" v-model="isSendingInviteMessage" />
      <label for="send-invite-message">
        {{ t("organisation.invite_users_with_a_message") }}
      </label>
    </div>
    <template v-if="isSendingInviteMessage">
      <LanguageSelect
        class="my-4"
        v-model="languageCode as LanguageCode"
        :isMultiChoice="false"
      />
      <TextArea
        name="inviteMessage"
        v-model="inviteMessage"
        :label="t('organisation.invite_message')"
        :placeholder="t('organisation.invite_message_placeholder')"
        :maxlength="500"
        :rows-length="5"
        fullWidth
      />
    </template>

    <template #actions>
      <div class="flex justify-end">
        <GButton
          @click="inviteUsers()"
          :disabled="invitedEmails.emails.length === 0"
        >
          {{ t("organisation.invite_users") }}
        </GButton>
      </div>
    </template>
  </BulkActionsModal>
</template>
