import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { parseISO, startOfDay } from "date-fns";

import { useFetch, usePersistedFilter } from "@/composables";
import { useNotificationsStore, useUserStore } from "@/stores";
import { useUserActionValidationRules } from "./userActionValidationRules";
import type {
  UserBulkActionId,
  ManageUsersTableDataRow,
  PerformUserActionInput,
  UserActionId,
  UserSingleAction,
  UserBulkAction,
  UsersResponse,
  Filters,
} from "./types";
import {
  USER_STATUS,
  userStatusValueToKey,
  type UserStatusValue,
} from "@/constants/user";
import type { UserStatusOption } from "@/patterns";
import type { UserActionValidationResult } from "./userActionValidationRules";
import { getPayloadTags } from "@/pages/Reports/utils";

export const useUserActionsStore = defineStore("userActions", () => {
  const { t } = useI18n();
  const { showNotification } = useNotificationsStore();
  const {
    getBlockUsersValidationResults,
    getRevokeInvitationUsersValidationResults,
    getAnonymiseUsersValidationResults,
    allowAll,
  } = useUserActionValidationRules();

  const activeAction = ref<UserActionId | UserBulkActionId | null>(null);
  const activeRow = ref<ManageUsersTableDataRow | null>(null);
  const selectedUsers = ref<Array<ManageUsersTableDataRow>>([]);
  const isActionRequestPending = ref(false);
  const validationResult = ref<UserActionValidationResult | null>(null);
  const excludedFromActionCount = computed(
    () => validationResult.value?.excluded.length,
  );

  const initialFilters: Filters = {
    search: "",
    teams: [null],
    roles: [],
    status: -1 as UserStatusOption,
    activationDate: null,
  };

  const { filters, resetFilters } = usePersistedFilter<Filters>(initialFilters);

  const postPayload = computed(() => ({
    isOptingInNewDashboard: true,
    SearchFor: filters.value.search,
    Tags: getPayloadTags(filters.value.teams),
    Roles: filters.value.roles,
    PersonStatusId: filters.value.status,
    DateFrom: startOfDay(filters.value.activationDate?.[0]),
    DateTo: filters.value.activationDate?.[1],
  }));

  const {
    data: usersListResponse,
    isFetching,
    error: usersListError,
  } = useFetch(`/api/ReportRegisteredStudents2`, { refetch: true })
    .post(postPayload)
    .json<UsersResponse>();

  const userList = computed(() =>
    usersListResponse.value?.Data.map((user) => ({
      id: user.PersonId,
      name: `${user.FirstName} ${user.LastName}`,
      fuid: user.ForeignUsername,
      email: user.Email,
      roles: user.Roles,
      teams: user.Teams,
      status: user.StatusId as UserStatusValue,
      activationDate:
        !user.ActivationDate || user.StatusId < USER_STATUS.active
          ? null
          : parseISO(user.ActivationDate),
      voucherId: user.InvitedVoucherId,
    })),
  );

  const userActions: UserSingleAction[] = [
    // {
    //   id: "resendInvitation",
    //   label: t("organisation.resend_invitation"),
    //   action: () => (activeAction.value = "resendInvitation"),
    //   isAllowed: (selectedUsers) =>
    //     selectedUsers.some((user) => user.status === USER_STATUS.invited),
    // },
    {
      id: "addToTeam",
      label: t("organisation.add_to_team"),
      validate: allowAll,
      action: () => {
        activeAction.value = "addToTeam";
        validationResult.value = allowAll(
          activeRow.value ? [activeRow.value] : selectedUsers.value,
        );
      },
    },
    {
      id: "revokeInvitation",
      label: t("organisation.revoke_invitation"),
      validate: getRevokeInvitationUsersValidationResults,
      action: () => {
        activeAction.value = "revokeInvitation";
        validationResult.value = getRevokeInvitationUsersValidationResults(
          activeRow.value ? [activeRow.value] : selectedUsers.value,
        );
      },
    },
    // {
    //   id: "assignRole",
    //   label: t("organisation.assign_role"),
    //   action: () => (activeAction.value = "assignRole"),
    //   isAllowed: (selectedUsers) =>
    //     selectedUsers.some(
    //       (user) =>
    //         user.status === USER_STATUS.invited ||
    //         user.status === USER_STATUS.active,
    //     ),
    // },
    // {
    //   id: "resetPassword",
    //   label: t("organisation.reset_password"),
    //   action: () => (activeAction.value = "resetPassword"),
    //   isAllowed: (selectedUsers) =>
    //     selectedUsers.some((user) => user.status === USER_STATUS.active),
    // },
    {
      id: "export",
      label: t("common.export"),
      validate: allowAll,
      action: () => {
        activeAction.value = "export";
        validationResult.value = allowAll(
          activeRow.value ? [activeRow.value] : selectedUsers.value,
        );
      },
    },
    // {
    //   id: "unblockUser",
    //   label: t("organisation.unblock"),
    //   action: () => (activeAction.value = "unblockUser"),
    //   isAllowed: (selectedUsers) =>
    //     selectedUsers.some((user) => user.status === USER_STATUS.blocked),
    // },
    {
      id: "anonymiseUser",
      label: t("organisation.anonymise"),
      isDestructive: true,
      validate: getAnonymiseUsersValidationResults,
      action: () => {
        activeAction.value = "anonymiseUser";
        validationResult.value = getAnonymiseUsersValidationResults(
          activeRow.value ? [activeRow.value] : selectedUsers.value,
        );
      },
    },
    {
      id: "blockUser",
      label: t("organisation.block"),
      isDestructive: true,
      validate: getBlockUsersValidationResults,
      action: () => {
        activeAction.value = "blockUser";
        validationResult.value = getBlockUsersValidationResults(
          activeRow.value ? [activeRow.value] : selectedUsers.value,
        );
      },
    },
  ];

  const userBulkActions = computed<UserBulkAction[]>(() => [
    // {
    //   id: "bulkResendInvitation",
    //   label: t("organisation.resend_invitation"),
    //   action: () => (activeAction.value = "bulkResendInvitation"),
    // },
    {
      id: "bulkRevokeInvitation",
      label: t("organisation.revoke_invitation"),
      action: () => (activeAction.value = "bulkRevokeInvitation"),
    },
    {
      id: "bulkAddToTeam",
      label: t("organisation.add_to_team"),
      action: () => (activeAction.value = "bulkAddToTeam"),
    },
    // {
    //   id: "bulkAssignRole",
    //   label: t("organisation.assign_role"),
    //   action: () => (activeAction.value = "bulkAssignRole"),
    // },
    // {
    //   id: "bulkResetPassword",
    //   label: t("organisation.reset_password"),
    //   action: () => (activeAction.value = "bulkResetPassword"),
    // },
    {
      id: "bulkAnonymiseUser",
      label: t("organisation.anonymise"),
      action: () => (activeAction.value = "bulkAnonymiseUser"),
      isDestructive: true,
    },
    {
      id: "bulkBlockUser",
      label: t("organisation.block"),
      action: () => (activeAction.value = "bulkBlockUser"),
      isDestructive: true,
    },
  ]);

  const closeModal = () => {
    activeAction.value = null;
    activeRow.value = null;
    validationResult.value = null;
  };

  /**
   * Get IDs of entities eligible for the current action
   * If a single row is active, use that ID
   * If multiple rows are selected, use those IDs
   */
  const selectedUserIds = computed(() => {
    if (activeRow.value) return [activeRow.value.id];
    return selectedUsers.value.map((user) => user.id);
  });

  const allowedIdsForAction = computed(() => {
    if (!validationResult.value) return [];
    return validationResult.value.allowed.map((user) => user.id);
  });

  const getAvailableActionsFor = (users: ManageUsersTableDataRow[]) => {
    return userActions.filter((action) => {
      const validationResult = action.validate(users);
      return validationResult?.allowed.length > 0;
    });
  };

  const actionsForSelectedRows = computed(() =>
    getAvailableActionsFor(selectedUsers.value),
  );

  const actionsForRow = computed(() =>
    activeRow.value ? getAvailableActionsFor([activeRow.value]) : [],
  );

  const exportUsersFileHeaders = computed(() => [
    t("reports.tbl_col_email"),
    t("common.name"),
    t("organisation.role", 2),
    t("reports.teams"),
    t("common.status"),
    t("organisation.activation_date"),
  ]);

  const exportUsersFileRows = () => {
    const { roleCodeToLabel } = useUserStore();
    return selectedUsers.value.map((user) => [
      user.email,
      user.name,
      user.roles.map((roleCode) => roleCodeToLabel[roleCode]).join(", "),
      user.teams.map(({ Name }) => Name).join(", "),
      t("user_status." + userStatusValueToKey[user.status]),
      user.activationDate,
    ]);
  };

  const performUserAction = async <TResponse = unknown>({
    request,
    successMessage,
    errorMessage,
  }: PerformUserActionInput<TResponse>) => {
    let responseData: TResponse;

    try {
      isActionRequestPending.value = true;
      const { response } = await useFetch(request.url, {
        method: request.method,
        body: JSON.stringify(request.body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.value.ok) {
        throw new Error(response.value.statusText);
      }

      // Read the response body only once and handle it appropriately
      // We use .text() because some responses are empty and parsing the json will throw an error.
      const responseText = await response.value.text();
      if (responseText.length > 0) {
        responseData = JSON.parse(responseText) as TResponse;
      }

      showNotification({
        type: "success",
        message:
          typeof successMessage === "function"
            ? successMessage(responseData)
            : successMessage,
      });
    } catch (error: unknown) {
      showNotification({
        type: "danger",
        message:
          typeof errorMessage === "function"
            ? errorMessage({ data: responseData, error })
            : errorMessage,
      });
    } finally {
      activeAction.value = null;
      isActionRequestPending.value = false;
      validationResult.value = null;
    }
  };

  return {
    activeAction,
    activeRow,
    selectedUsers,
    isActionRequestPending,
    validationResult,
    excludedFromActionCount,
    initialFilters,
    filters,
    resetFilters,
    userList,
    usersListResponse,
    isFetching,
    usersListError,
    actionsForSelectedRows,
    actionsForRow,
    selectedUserIds,
    allowedIdsForAction,
    userBulkActions,
    closeModal,
    exportUsersFileHeaders,
    exportUsersFileRows,
    performUserAction,
  };
});

export default useUserActionsStore;
