import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { differenceInDays, getMonth, getTime, sub } from "date-fns";

import { useCourseStore, useLessonsStore } from "@/stores";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { usePersistedFilter, useQueryString } from "@/composables";
import { getPayloadTags } from "../utils";

export const useDetailsPage = () => {
  const isLoading = ref(true);
  const { courseDetailsReport, courses } = storeToRefs(useCourseStore());
  const { formatReport } = useCourseStore();
  const { lessons } = storeToRefs(useLessonsStore());
  const { params, query } = useRoute();
  const { t } = useI18n();

  const { filters } = usePersistedFilter<{
    teams: number[];
    date: [Date, Date];
  }>({
    teams: [null],
    date: [sub(new Date(), { days: 7 }), new Date()],
  });

  const { queryString } = useQueryString();
  watch(
    [courses, lessons, filters],
    async ([updatedCourses, updatedLessons, updatedFilters]) => {
      isLoading.value = true;
      if (
        updatedCourses.length === 0 ||
        !updatedLessons ||
        updatedLessons.length === 0
      ) {
        return;
      }

      await formatReport({
        containerId: params.courseContainerId as string,
        nodeTypeId: Number(query.type),
        startDate: updatedFilters.date[0],
        endDate: updatedFilters.date[1],
        ...(getPayloadTags(updatedFilters.teams) && {
          tags: getPayloadTags(updatedFilters.teams),
        }),
      });
      isLoading.value = false;
    },
    { deep: true, immediate: true },
  );

  const groupCountPerMonthAndYear = (
    data: Array<{ date: Date; count: number }>,
  ): Record<string, number> => {
    const result: Record<string, number> = {};
    for (const dataPoint of data) {
      const date = dataPoint.date;
      const month = getMonth(date) + 1;
      const year = date.getFullYear();
      const key = `${year}-${month}`;
      if (result[key]) {
        result[key] += dataPoint.count;
      } else {
        result[key] = dataPoint.count;
      }
    }
    return result;
  };

  const formattedActivityVisitsData = computed(() => {
    if (!courseDetailsReport.value) {
      return { labels: [], data: [] };
    }

    // if less then 60 days, show days individually
    if (differenceInDays(filters.value.date[1], filters.value.date[0]) < 60) {
      return {
        labels: courseDetailsReport.value.activityVisits.map((dataPoint) =>
          getTime(dataPoint.date),
        ),
        data: courseDetailsReport.value.activityVisits.map(
          (dataPoint) => dataPoint.count,
        ),
      };
    }

    const groupedData = groupCountPerMonthAndYear(
      courseDetailsReport.value.activityVisits,
    );
    return {
      labels: Object.keys(groupedData).map((key) => getTime(new Date(key))),
      data: Object.values(groupedData),
    };
  });

  const statsData = computed(() => [
    {
      icon: "user",
      data: [
        {
          label: t("courseDetails.stats_total_students"),
          value:
            courseDetailsReport.value?.totalStudentsCount.toString(10) ?? "0",
        },
        {
          label: t("courseDetails.stats_new_students"),
          value:
            courseDetailsReport.value?.newStudentsCount.toString(10) ?? "0",
        },
      ],
    },
    {
      icon: "fileCertificate",
      data: [
        {
          label: t("courseDetails.stats_course_certificates"),
          value:
            courseDetailsReport.value?.courseCertificatesCount.toString(10) ??
            "0",
        },
        {
          label: t("courseDetails.stats_test_certificates"),
          value:
            courseDetailsReport.value?.testCertificatesCount.toString(10) ??
            "0",
        },
      ],
    },
    {
      icon: "clock",
      data: [
        {
          label: t("reports.tbl_col_study_time"),
          value: courseDetailsReport.value?.studyTime ?? "0",
        },
      ],
    },
  ]);

  return {
    isLoading,
    courseDetailsReport,
    formattedActivityVisitsData,
    statsData,
    filters,
    queryString,
  };
};
