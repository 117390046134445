<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { GButton, GModal, GRadioGroup } from "@/components";
import type { ExportTableData } from "@/types/export";
import { exportToCSV, exportToExcel } from "@/utils/export";
import { useExportStore } from "@/stores";
import { useMixpanel } from "@/composables";

const { t } = useI18n();
const exportStore = useExportStore();
const { track } = useMixpanel();
const { selectedFileFormat, fileFormatOptions } = storeToRefs(exportStore);

type ExportModalProps = {
  isOpen: boolean;
  fileName: string;
  data: ExportTableData;
  title?: string;
  isLoading?: boolean;
  onBeforeExport?: () => Promise<boolean>;
};

const props = defineProps<ExportModalProps>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "export"): void;
}>();

const exportContent = async () => {
  if (props.onBeforeExport) {
    const canExport = await props.onBeforeExport();
    if (!canExport) {
      return;
    }
  }

  if (selectedFileFormat.value === "csv") {
    exportToCSV(props.data, props.fileName);
  } else if (selectedFileFormat.value === "excel") {
    await exportToExcel(props.data, props.fileName);
  }
  track("export", {
    file: props.fileName,
    format: selectedFileFormat.value,
  });
  emit("export");
  emit("close");
};

const router = useRouter();
const viewInBrowser = (name: string, data: ExportTableData) => {
  sessionStorage.setItem(name, JSON.stringify(data));
  const route = router.resolve({
    name: "exportDataTable",
    params: { type: name },
  });
  window.open(route.href, "_blank");
};
</script>
<template>
  <GModal
    :isOpen="isOpen"
    scrollBehaviour="modal"
    :title="title || t('contentSelection.export')"
    class="md:min-w-[25rem]"
    @close="$emit('close')"
    data-testid="export-modal"
  >
    <div class="flex flex-col">
      <slot></slot>

      <GRadioGroup
        class="mb-6"
        v-model="selectedFileFormat"
        :options="fileFormatOptions"
        name="selectedFileFormat"
        ><template #label>
          {{ t("contentSelection.format") }}
        </template>
      </GRadioGroup>
    </div>
    <template #actions>
      <div class="flex flex-col items-center gap-2">
        <GButton
          class="w-full"
          @click="exportContent"
          :isLoading="isLoading"
          data-testid="export-modal-export-btn"
          >{{ t("contentSelection.export") }}</GButton
        >
        <template v-if="onBeforeExport === undefined">
          {{ t("common.or").toUpperCase() }}
          <button
            class="text-primary underline"
            @click="() => viewInBrowser(fileName, data)"
          >
            {{ t("common.see_in_the_browser") }}
          </button>
        </template>
      </div>
    </template>
  </GModal>
</template>
