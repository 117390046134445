<script setup lang="ts">
import { computed, ref } from "vue";
import { isSameDay, isDate, isValid, format } from "date-fns";
import Datepicker from "@vuepic/vue-datepicker";
import { TextInput } from "@/components";
import { PresetId, useDatePicker } from "./useDatePicker";
import { isDateTuple } from "@/utils/typeGuards";

import CalendarIcon from "@/assets/images/icons/calendar.svg";
import CloseIcon from "@/assets/images/icons/close.svg";

const { defaultPresetRanges } = useDatePicker();

const props = withDefaults(
  defineProps<{
    label: string;
    modelValue: Date | [Date, Date] | null;
    transitions?: boolean;
    enableTimePicker?: boolean;
    disablePresetRanges?: boolean;
    presetRanges?: Array<PresetId>;
    range?: boolean;
    noToday?: boolean;
    keepActionRow?: boolean;
    placeholder?: string;
    autoApply?: boolean;
    withCustomRangeLabel?: boolean;
  }>(),
  {
    range: true,
    enableTimePicker: false,
    autoApply: true,
    transitions: false,
    noToday: false,
    keepActionRow: false,
    withCustomRangeLabel: false,
  },
);

const emit = defineEmits<{
  (event: "update:modelValue", value: Date | [Date, Date] | null): void;
}>();

const datepicker = ref();

const initialValue = ref(props.modelValue);

const clearValue = () => {
  emit("update:modelValue", initialValue.value);
  datepicker.value.closeMenu();
};

const presets = computed(() => {
  if (props.disablePresetRanges) return undefined;
  if (props.presetRanges)
    return props.presetRanges
      .map((rangeId) =>
        defaultPresetRanges.find((range) => range.id === rangeId),
      )
      .filter(Boolean) as typeof defaultPresetRanges;
  else return defaultPresetRanges;
});

const formattedDateLabel = computed(() => {
  if (isDate(props.modelValue) && isValid(props.modelValue)) {
    return format(props.modelValue as Date, "dd/MM/yyyy");
  }
  if (isDateTuple(props.modelValue)) {
    if (isSameDay(props.modelValue[0], props.modelValue[1])) {
      return format(props.modelValue[0], "PP");
    }

    return `${format(props.modelValue[0], "PP")} - ${format(
      props.modelValue[1],
      "PP",
    )}`;
  }
  return "";
});
</script>

<template>
  <Datepicker
    ref="datepicker"
    data-testid="date-picker"
    :range="range"
    :keepActionRow="props.keepActionRow"
    :noToday="props.noToday"
    :maxDate="new Date()"
    :transitions="props.transitions"
    :autoApply="props.autoApply"
    :presetDates="presets"
    :value="props.range"
    :clearable="false"
    :enableTimePicker="props.enableTimePicker"
    :modelValue="props.modelValue"
    @update:modelValue="emit('update:modelValue', $event)"
  >
    <template #dp-input>
      <TextInput
        class="min-w-60"
        fullWidth
        data-testid="datepicker-input-trigger"
        name="date"
        :placeholder="props.placeholder"
        :modelValue="formattedDateLabel"
        :label="props.label"
        @keydown.enter="datepicker.openMenu()"
        @keydown.space="datepicker.openMenu()"
        @keydown.escape="datepicker.closeMenu()"
      >
        <template #prefixIcon>
          <CalendarIcon />
        </template>
        <template #suffixIcon>
          <CloseIcon
            @click="clearValue"
            class="cursor-pointer"
            v-show="modelValue !== initialValue"
          />
        </template>
      </TextInput>
    </template>
  </Datepicker>
</template>
<style lang="scss">
$dp__font_family: "CircularXXTT", system-ui, sans-serif;
$dp__menu_min_width: 390px;

@import "@vuepic/vue-datepicker/dist/main.css";

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #eee8fd; /* blue-10 */
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #5a1eec; /* primary */
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #e1e1e1; /* grey-10 */
  --dp-menu-border-color: #e1e1e1; /* grey-10 */
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #eee8fd; /* blue-10 */
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: #eee8fd; /* blue-10 */
}

.dp__instance_calendar,
.dp__preset_ranges {
  @apply p-2;
}

.dp--clear-btn {
  transform: none;
  right: 0.7rem;
}

.dp--menu-wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
</style>
