<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { USER_STATUS } from "@/constants/user";

defineProps<{
  status: number;
}>();

const { t } = useI18n();

function getKeyByValue(value) {
  return Object.keys(USER_STATUS).find((key) => USER_STATUS[key] === value);
}

const variantClasses: Record<NonNullable<number>, string> = {
  1: "bg-info-light text-info",
  2: "bg-warning-light text-warning",
  3: "bg-success-light text-success",
  4: "bg-error-light text-error",
};
</script>

<template>
  <div class="w-fit rounded px-3 py-1" :class="[variantClasses[status]]">
    {{ t("user_status." + getKeyByValue(status)) }}
  </div>
</template>
