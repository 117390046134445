<script setup lang="ts">
import { useUiStore } from "../stores";
import { DashboardMenu } from "../components";

const uiStore = useUiStore();
</script>
<template>
  <div class="grid h-full grid-rows-[auto_1fr_auto]">
    <g-platform-navbar class="z-30"></g-platform-navbar>
    <div
      class="grid flex-1 bg-white transition-[grid-template-rows] duration-300 lg:grid-cols-[min-content_auto]"
      :class="
        uiStore.isMenuExpanded
          ? 'grid-rows-[1fr_auto] lg:grid-rows-1'
          : 'grid-rows-[0fr_auto] lg:grid-rows-1'
      "
    >
      <DashboardMenu class="row-span-2" />
      <Suspense>
        <RouterView />
      </Suspense>
    </div>
    <g-platform-footer />
  </div>
</template>
