import { useI18n } from "vue-i18n";
import {
  USER_STATUS,
  userStatusValueToKey,
  type UserStatusValue,
} from "@/constants/user";
import type { ManageUsersTableDataRow } from "./types";
import { useUserStore } from "@/stores";

export const useUserActionValidationRules = () => {
  const userStore = useUserStore();
  const { t } = useI18n();

  function allowAll(selectedUsers: ManageUsersTableDataRow[]) {
    return {
      allowed: selectedUsers,
      excluded: [],
    };
  }

  function getBlockUsersValidationResults(
    selectedUsers: ManageUsersTableDataRow[],
  ) {
    return getValidationResults(selectedUsers, [
      excludeCurrentUser,
      includeUserWithStatuses([
        USER_STATUS.created,
        USER_STATUS.invited,
        USER_STATUS.active,
      ]),
    ]);
  }

  function getRevokeInvitationUsersValidationResults(
    selectedUsers: ManageUsersTableDataRow[],
  ) {
    return getValidationResults(selectedUsers, [
      excludeCurrentUser,
      includeUserWithStatuses([USER_STATUS.invited]),
    ]);
  }

  function getAnonymiseUsersValidationResults(
    selectedUsers: ManageUsersTableDataRow[],
  ) {
    return getValidationResults(selectedUsers, [
      excludeCurrentUser,
      excludeAnonymisedUser,
    ]);
  }

  function excludeCurrentUser(user: ManageUsersTableDataRow) {
    return {
      allowed: user.email !== userStore.user?.Email,
      reason: t("organisation.exclude_current_user_error"),
    };
  }

  function excludeAnonymisedUser({
    email,
    name,
    fuid,
  }: ManageUsersTableDataRow) {
    return {
      allowed: Boolean(fuid) || (email !== "-" && name !== "--"),
      reason: t("organisation.exclude_anonymised_user_error"),
    };
  }

  function includeUserWithStatuses(statuses: UserStatusValue[]) {
    return (user: ManageUsersTableDataRow) => ({
      allowed: statuses.includes(user.status),
      reason: t("organisation.wrong_status.multiple_statuses_message", {
        statuses: statuses.map((s) =>
          t(`user_status.${userStatusValueToKey[s]}`),
        ),
      }),
    });
  }

  function getValidationResults(
    selectedUsers: ManageUsersTableDataRow[],
    conditions: Array<
      (user: ManageUsersTableDataRow) => { allowed: boolean; reason?: string }
    >,
  ): UserActionValidationResult {
    const allowed = selectedUsers.filter((user) =>
      conditions.every((condition) => condition(user).allowed),
    );
    const excluded = selectedUsers
      .filter(
        (user) => !conditions.every((condition) => condition(user).allowed),
      )
      .map((user) => {
        const failedCondition = conditions.find(
          (condition) => !condition(user).allowed,
        );
        return {
          user,
          reason:
            failedCondition?.(user).reason ||
            t("organisation.user_not_eligible_for_action_error"),
        };
      });

    return {
      allowed,
      excluded,
    };
  }

  return {
    getBlockUsersValidationResults,
    getRevokeInvitationUsersValidationResults,
    getAnonymiseUsersValidationResults,
    allowAll,
  };
};

export type UserActionValidationResult = {
  allowed: ManageUsersTableDataRow[];
  excluded: Array<{
    user: ManageUsersTableDataRow;
    reason: string;
  }>;
};
