<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import useUserActionsStore from "../store";
import { useLocale, useMixpanel } from "@/composables";
import BaseUserActionModal from "./BaseUserActionModal.vue";

const { t } = useI18n();
const { track } = useMixpanel();
const { locale } = useLocale();
const {
  userList,
  activeAction,
  isActionRequestPending,
  selectedUserIds,
  allowedIdsForAction,
  activeRow,
  excludedFromActionCount,
} = storeToRefs(useUserActionsStore());
const { performUserAction, closeModal } = useUserActionsStore();

const voucherIds = computed(() =>
  userList.value
    .filter((user) => allowedIdsForAction.value.includes(user.id))
    .map((user) => user.voucherId),
);

const revokeInvitationsByVoucherIds = () => {
  performUserAction<{ title: string }>({
    request: {
      url: "/api/ActivityQueue",
      method: "PUT",
      body: {
        command: "revoke_by_voucherid",
        data: voucherIds.value.join(","),
        languagecode: locale.value,
      },
    },
    successMessage: t("organisation.generic_action_success", {
      n: allowedIdsForAction.value.length,
      action: t("organisation.action.revoke_invitation"),
    }),
    errorMessage: t(
      "organisation.generic_action_error",
      { action: t("organisation.action.revoke_invitation") },
      { plural: selectedUserIds.value.length },
    ),
  });

  track("userRevokeInvitation", {
    user_count: voucherIds.value.length,
    action_mode: activeRow.value ? "single" : "bulk_selection",
  });
};
</script>
<template>
  <BaseUserActionModal
    actionId="revokeInvitation"
    :isOpen="activeAction === 'revokeInvitation'"
    :title="t('organisation.revoke_invitation')"
    :description="t('organisation.modal_revoke_invitation_description')"
    :excludedCount="excludedFromActionCount"
    :confirmButtonProps="{
      text: t('organisation.revoke'),
      variant: 'primary',
      isLoading: isActionRequestPending,
    }"
    @close="closeModal"
    @confirm="revokeInvitationsByVoucherIds()"
  />
</template>
