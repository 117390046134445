<script setup lang="ts">
import { computed, type ComputedRef } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

import {
  useAssessmentsStore,
  useEntitlementsStore,
  useFlagStore,
  useUserStore,
} from "@/stores";
import { GTabs, PageLayout, type Tab } from "@/components";

const { t } = useI18n();

const asmStore = useAssessmentsStore();
const { checkEntitlementEnabled } = useEntitlementsStore();
const { isAuthorizedForRoute } = useUserStore();

const flagStore = useFlagStore();
const { lessonsFlag } = storeToRefs(flagStore);

const contentTabs: ComputedRef<Tab[]> = computed(() => {
  return [
    {
      label: t("navigation.courses"),
      id: "courses",
      href: "courses",
      isHidden: !(
        checkEntitlementEnabled("entConnectionSelectionCourses") &&
        isAuthorizedForRoute("courses")
      ),
    },
    {
      label: t("navigation.lessons"),
      id: "lessons",
      href: "lessons",
      isHidden:
        !lessonsFlag.value ||
        !(
          checkEntitlementEnabled("entConnectionSelectionLessons") &&
          isAuthorizedForRoute("lessons")
        ),
    },
    {
      label: t("navigation.assessments"),
      id: "assessments",
      href: "assessments",
      isHidden:
        !asmStore.isAssessmentsFullUser ||
        !(
          checkEntitlementEnabled("entConnectionSelectionAssessments") &&
          isAuthorizedForRoute("assessments")
        ),
    },
    {
      label: t("navigation.single_activities"),
      id: "single-activities",
      href: "single-activities",
      isHidden: !(
        checkEntitlementEnabled("entConnectionSelectionSingleActivities") &&
        isAuthorizedForRoute("single-activities")
      ),
    },
    {
      label: t("navigation.goodscan"),
      id: "goodscan",
      href: "goodscan",
      isHidden: !(
        checkEntitlementEnabled("entConnectionSelectionGoodscan") &&
        isAuthorizedForRoute("goodscan")
      ),
    },
  ];
});
</script>
<template>
  <PageLayout
    :title="t('contentSelection.title')"
    :heading="t('contentSelection.title')"
  >
    <GTabs :tabs="contentTabs" />
  </PageLayout>
</template>
