<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GDropdown } from "@/components";

const { t } = useI18n();
const options = [
  { label: t("reports.unpublished"), value: 0 },
  { label: t("reports.published"), value: 1 },
];

const selectedStatus = defineModel<number | null>({
  default: null,
  required: true,
});
</script>
<template>
  <GDropdown
    :options="options"
    :value="selectedStatus"
    :label="t('common.status')"
    :hasDefault="true"
    @change="selectedStatus = $event as number"
  />
</template>
