<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GAlert } from "@/components";
import useUserActionsStore from "../store";
import { storeToRefs } from "pinia";
import { useLocale, useMixpanel } from "@/composables";
import BaseUserActionModal from "./BaseUserActionModal.vue";

const { closeModal, performUserAction } = useUserActionsStore();
const {
  activeAction,
  selectedUserIds,
  allowedIdsForAction,
  excludedFromActionCount,
  activeRow,
} = storeToRefs(useUserActionsStore());
const { t } = useI18n();
const { track } = useMixpanel();
const { locale } = useLocale();

const blockUsersByIds = () => {
  performUserAction({
    request: {
      url: "/api/ActivityQueue",
      method: "DELETE",
      body: {
        command: "remove_user_by_personid",
        data: allowedIdsForAction.value.join(","),
        languageCode: locale.value,
        removeMailTxt: "",
      },
    },
    successMessage: t("organisation.generic_action_success", {
      n: allowedIdsForAction.value.length,
      action: t("organisation.action.block"),
    }),
    errorMessage: t(
      "organisation.generic_action_error",
      { action: t("organisation.action.block") },
      { plural: selectedUserIds.value.length },
    ),
  });

  track("userBlock", {
    user_count: selectedUserIds.value.length,
    action_mode: activeRow.value ? "single" : "bulk_selection",
  });
};
</script>
<template>
  <BaseUserActionModal
    actionId="blockUser"
    data-testId="block-modal"
    :isOpen="activeAction === 'blockUser'"
    :title="t('organisation.block_user(s)', selectedUserIds.length || 1)"
    :description="t('organisation.modal_block_description')"
    :excludedCount="excludedFromActionCount"
    :confirmButtonProps="{
      text: t('organisation.block'),
      variant: 'danger',
    }"
    @close="closeModal()"
    @confirm="blockUsersByIds()"
  >
    <GAlert
      :dismissible="false"
      variant="danger"
      :message="t('organisation.modal_block_warning')"
    />
  </BaseUserActionModal>
</template>
