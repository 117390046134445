<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { InsightCard, TileBackground } from "@/components";
import { GTable, type TableColumn } from "@/patterns";
import { useSectionFetch } from "./useSectionFetch";
import { useLocale } from "@/composables";

import NoPreview from "@/assets/images/no-preview.svg?url";
import { SearchInput } from "@/patterns";

type ActivityPerAssessmentResponse = {
  accessRight: string;
  id: string;
  title: string;
  usersCount: number;
  completedCount: number;
  tile: {
    backgroundImage: {
      uri: string;
      title: string;
    };
    backgroundColor: string;
    backgroundShape: string;
    backgroundShapePosition: string;
  };
}[];

const props = defineProps<{
  startDate: number;
  endDate: number;
}>();

const startDateRef = toRef(props, "startDate");
const endDateRef = toRef(props, "endDate");

const { execute, data, isFetching, error } =
  useSectionFetch<ActivityPerAssessmentResponse>(
    "AssessmentsActivityReport",
    startDateRef,
    endDateRef,
  );

const { t } = useI18n();
const { locale } = useLocale();

type ActivityPerAssessmentTableDataRow = {
  thumbnail: string;
  title: string;
  userCount: number;
  completedCount: number;
};

type ColumnAccessor = "thumbnail" | "title" | "userCount" | "completedCount";
const tableDefinition: TableColumn<ColumnAccessor>[] = [
  {
    id: "thumbnail",
    headerLabel: t("common.thumbnail"),
    accessor: "thumbnail",
    sortable: false,
  },
  {
    id: "title",
    headerLabel: t("common.title"),
    accessor: "title",
  },
  {
    id: "userCount",
    headerLabel: t("assessments.user_count"),
    accessor: "userCount",
  },
  {
    id: "completedCount",
    headerLabel: t("assessments.completed_count"),
    accessor: "completedCount",
  },
];

const asmList = computed<ActivityPerAssessmentTableDataRow[]>(
  () =>
    data.value
      ?.filter((asm) => asm.accessRight === "startable")
      .map((asm) => ({
        thumbnail: asm.tile?.backgroundImage?.uri || "",
        backgroundShape: asm.tile?.backgroundShape || "",
        backgroundColor: asm.tile?.backgroundColor || "",
        backgroundShapePosition: asm.tile?.backgroundShapePosition || "",
        id: asm.id,
        title: asm.title,
        userCount: asm.usersCount,
        completedCount: asm.completedCount,
        assessmentLink: `https://my.goodhabitz.com/${locale.value}/assessments/${asm.id}`,
      })) ?? [],
);

const defaultSort = [{ desc: true, id: "completedCount" }];

const getNoPreviewThumbnail = (e: Event) => {
  (e.target as HTMLImageElement).src = NoPreview;
  (e.target as HTMLImageElement).alt = t("table.no_preview");
};
const search = ref("");
</script>
<template>
  <InsightCard
    class="lg:col-span-3"
    :title="$t('assessments.activity_per_asm_title')"
    :subtitle="$t('assessments.activity_per_asm_subtitle')"
    :isLoading="isFetching"
    :error="error"
    @retry="execute"
  >
    <template #primaryAction>
      <SearchInput v-model="search" />
    </template>
    <GTable
      :data="asmList"
      :columns="tableDefinition"
      :filter="{
        columns: [{ id: 'title', value: search }],
      }"
      :defaultSort="defaultSort"
      :selectable="false"
    >
      <template v-slot:title="cellProps">
        <a
          :href="cellProps.row.assessmentLink"
          target="_blank"
          rel="noopener noreferrer"
          class="mr-2 truncate"
        >
          {{ cellProps.value }}
        </a>
      </template>
      <template v-slot:thumbnail="cellProps">
        <div class="relative w-14">
          <TileBackground
            :shape="cellProps.row.backgroundShape"
            :color="cellProps.row.backgroundColor"
            :position="cellProps.row.backgroundShapePosition"
          />
          <img
            :src="cellProps.value as string"
            @error="getNoPreviewThumbnail"
            class="absolute bottom-0 left-0"
            :alt="cellProps.row?.title"
          />
        </div>
      </template>
    </GTable>
  </InsightCard>
</template>
