import mixpanel from "mixpanel-browser";
import type { LoggedInUser } from "@/types/websession";
import { isAnalyticsEnabled } from "./cookies";
import { getStartingLocale } from "@/utils/i18n";

export const initializeMixpanel = () => {
  return new Promise((resolve) => {
    mixpanel.init(import.meta.env.VITE_REACT_SANDWICH_MIXPANEL_TOKEN, {
      api_host: "https://api-eu.mixpanel.com",
      ip: false,
      loaded() {
        resolve(undefined);
      },
    });
  });
};

export function identifyUser(user: LoggedInUser) {
  const { ExternalPersonReference, CompanyId, Country } = user;

  const shouldIdentifyUser =
    isAnalyticsEnabled() &&
    ExternalPersonReference !== mixpanel.get_distinct_id();

  if (shouldIdentifyUser) {
    mixpanel.identify(ExternalPersonReference);
    mixpanel.people.set({ Country, CompanyId });
  }
}

export const mixPanelEvents = {
  pageView: "Pageview",
  singleActivitiesExport: "Single Activities Export",
  coursesExport: "Courses Export",
  assessmentsExport: "Assessments Export",
  lessonsExport: "Lessons Export",
  goodscanExport: "Goodscan Export",
  onboardingExit: "Onboarding Exit Setup",
  onboardingNext: "Onboarding Next Page",
  onboardingBack: "Onboarding Previous Page",
  launchAdminCourse: "Open Admin Onboarding Course",
  launchSignupWebinar: "Signup Webinar",
  launchHelpCenter: "Go To Helpcenter",
  launchSuccessPlanStep: "Start Success Plan Step",
  export: "Export",
  filter: "Filter",
  sort: "Sort",
  expand: "Expand",
  toggleDashboard: "Toggle Dashboard",
  userInvite: "User Management: Invite User",
  userRevokeInvitation: "User Management: Revoke Invitation",
  userBlock: "User Management: Block User",
  userAnonymize: "User Management: Anonymize User",
  userAddToTeam: "User Management: Add User To Team",
  userExport: "User Management: Export User",
  feedbackModalOpened: "Feedback Modal Opened",
  feedbackEmailLinkClicked: "Feedback Email Link Clicked",
} as const;

type TrackEventParams = Parameters<typeof mixpanel.track>;
export type MixpanelEventName = keyof typeof mixPanelEvents;
export type MixpanelContextName =
  | "Onboarding"
  | "Launch Page"
  | "Connect Page"
  | "Dashboard"
  | "Reports"
  | "Resources"
  | "User Management";

export const trackMixPanelEvent = (
  eventName: MixpanelEventName,
  properties?: { context?: MixpanelContextName } & TrackEventParams[1],
  optionsOrCallback?: TrackEventParams[2],
  callback?: TrackEventParams[3],
) => {
  const locale = getStartingLocale();
  const mixpanelEventProperties = {
    locale,
    ...properties,
  };
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(mixPanelEvents[eventName], mixpanelEventProperties);
  }

  if (!isAnalyticsEnabled()) return;

  try {
    mixpanel.track(
      mixPanelEvents[eventName],
      mixpanelEventProperties,
      optionsOrCallback,
      callback,
    );
  } catch {
    /* ignore */
  }
};
