<script setup lang="ts">
import { GTooltip } from "@/components";
import { useTemplateRef } from "vue";
defineProps<{
  name: string;
  fuid?: string;
  email?: string;
}>();

const identifierRef = useTemplateRef("identifier");
</script>
<template>
  <GTooltip
    class="bg-white [box-shadow:0px_4px_8px_0px_rgba(0,0,0,0.24)]"
    :hasArrow="false"
    :displayDelay="300"
  >
    <template #reference>
      <div
        class="flex w-72 auto-cols-min grid-cols-[min-content_1fr] grid-rows-2 flex-col gap-x-3 overflow-clip text-ellipsis md:grid md:items-center"
      >
        <div
          class="row-span-2 hidden h-[40px] w-[40px] items-center justify-center rounded-full bg-blue-10 text-blue md:flex"
        >
          {{
            name
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "")
          }}
        </div>
        <span class="inline-block overflow-hidden text-ellipsis">{{
          name
        }}</span>
        <span
          ref="identifier"
          class="inline-block overflow-hidden text-ellipsis"
          >{{ fuid || email }}</span
        >
      </div>
    </template>
    <template
      #content
      v-if="identifierRef?.offsetWidth < identifierRef?.scrollWidth"
    >
      <strong class="my-4 block pl-5 pr-11">{{ name }}</strong>
      <span :name="email" class="my-4 block pl-5 pr-11">{{
        fuid || email
      }}</span>
    </template>
  </GTooltip>
</template>
