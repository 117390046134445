<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import AnonymiseConfirmationStep from "./AnonymiseConfirmationStep.vue";
import type { BulkAnonymiseData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useUserActionsStore } from "../../store";
import type { BulkUserActionResponse } from "../../types";
import { useMixpanel } from "@/composables/useMixpanel";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
  {
    id: "anonymise-confirmation",
    component: AnonymiseConfirmationStep,
  },
];

const anonymiseData = ref<BulkAnonymiseData>({
  emails: [],
  confirmationText: "",
});

const { t } = useI18n();
const { performUserAction } = useUserActionsStore();
const { track } = useMixpanel();
const { activeAction, isActionRequestPending } = storeToRefs(
  useUserActionsStore(),
);

const anonymiseUsers = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/AnonymizeUser/ByEmailAddresses",
      method: "POST",
      body: {
        EmailAddresses: anonymiseData.value.emails,
      },
    },
    successMessage: (data) =>
      t("organisation.generic_action_success", {
        n: data.enqueuedEntriesCount,
        action: t("organisation.action.anonymise"),
      }),
    errorMessage: ({ data }) =>
      t("organisation.generic_action_error", {
        n: data.excludedEntries.length,
        reason: data.excludedEntries[0].reason,
        action: t("organisation.action.anonymise"),
      }),
  });

  track("userAnonymize", {
    user_count: anonymiseData.value.emails.length,
    action_mode: "bulk_email",
  });
};
</script>
<template>
  <BulkActionsModal
    :isOpen="activeAction === 'bulkAnonymiseUser'"
    :data="anonymiseData"
    :steps="steps"
    :title="t('organisation.anonymise_users')"
    :subtitle="t('organisation.modal_bulk_anonymise_subtitle')"
    :confirmButtonProps="{
      label: t('organisation.anonymise'),
      variant: 'danger',
      isLoading: isActionRequestPending,
    }"
    @confirm="anonymiseUsers()"
    @close="activeAction = null"
  />
</template>
