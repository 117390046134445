<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { twMerge } from "tailwind-merge";
import { GButton } from "@/components";

const props = defineProps<{
  title?: {
    text: string;
    icon: string;
  };
  heading: string;
  description: string;
  actionLabel?: string;
  skippable?: boolean;
  contentClassName?: string;
}>();
const emit = defineEmits<{
  (event: "goNext"): void;
}>();

const { t } = useI18n();
</script>
<template>
  <div
    class="mx-auto -mt-10 grid h-full max-w-7xl grid-cols-1 items-center justify-between gap-y-6 md:grid-cols-12 md:place-content-center md:gap-0"
  >
    <div :class="twMerge(`col-span-1 md:col-span-5 ${props.contentClassName}`)">
      <div v-if="props.title" class="mb-3 flex gap-2 font-medium text-primary">
        <component :is="props.title.icon" />
        <span>{{ props.title.text }}</span>
      </div>
      <p class="mb-4 text-xl font-bold md:mb-9 md:text-3xl">
        {{ props.heading }}
      </p>
      <p class="text-grey-80">{{ props.description }}</p>
      <slot name="additionalContent"></slot>
      <div class="mb-2 mt-4 md:mt-8">
        <GButton @click="emit('goNext')">
          <span class="md:px-8">
            {{ actionLabel }}
          </span></GButton
        >
      </div>
      <GButton v-if="props.skippable" variant="link" @click="emit('goNext')">
        {{ t("onboarding.skip_step") }}</GButton
      >
    </div>
    <div class="col-span-1 md:col-span-6 md:col-start-7">
      <slot name="media"></slot>
    </div>
  </div>
</template>
