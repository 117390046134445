<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { GButton } from "@/components";
import { useToastStore } from "@/stores/toasts";
import SaveIcon from "@/assets/images/icons/save.svg";

const props = defineProps<{
  keyString: string;
  valueString: string;
  keyClasses?: string;
  copyable?: boolean;
}>();

const { t } = useI18n();
const toastStore = useToastStore();

const isHovered = ref(false);

const handleCopyToClipboard = () => {
  navigator.clipboard.writeText(props.valueString);
  toastStore.addToast({
    header: t("common.copied"),
    body: t("common.copied_link_to_clipboard"),
  });
};
</script>
<template>
  <div
    class="flex flex-wrap items-center gap-2 py-2 lg:flex-nowrap"
    :class="[isHovered && 'bg-gray-100', copyable && 'px-2']"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @focus="isHovered = true"
    @blur="isHovered = false"
  >
    <span
      class="label-text mr-4 inline-block min-w-[6rem] font-medium capitalize text-black"
      :class="keyClasses"
    >
      {{ keyString }}
    </span>
    <span>{{ valueString }}</span>

    <GButton v-if="copyable" class="ml-auto" @click="handleCopyToClipboard">
      <template #prefixIcon><SaveIcon /></template>
      {{ t("common.copy") }}</GButton
    >
  </div>
</template>
