<script setup lang="ts">
import { GButton, GModal } from "@/components";
import { useI18n } from "vue-i18n";
import { useMixpanel } from "@/composables";
const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const { t } = useI18n();
const { track } = useMixpanel();

const handleSendFeedback = () => {
  track("feedbackEmailLinkClicked");
  emit("close");
};
</script>
<template>
  <GModal
    :is-open="props.isOpen"
    :title="t('feedback_modal.title')"
    class="grid max-w-[480px] gap-6"
    @close="emit('close')"
  >
    <div class="grid gap-6">
      <p class="text-2xl font-bold">{{ t("feedback_modal.greeting") }}</p>
      <p>{{ t("feedback_modal.paragraph_1") }}</p>
      <i18n-t keypath="feedback_modal.paragraph_2" tag="p">
        <template #email>
          <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
          <span class="font-bold">feedback@goodhabitz.com</span>
        </template>
      </i18n-t>
      <p>{{ t("feedback_modal.paragraph_3") }}</p>
      <div>
        <p>{{ t("feedback_modal.cheers") }}</p>
        <p>{{ t("feedback_modal.signature") }}</p>
      </div>
    </div>
    <template #actions>
      <div class="flex">
        <GButton
          href="mailto:feedback@goodhabitz.com?subject=GoodHabitz Feedback"
          @click="handleSendFeedback"
          class="flex"
        >
          {{ t("feedback_modal.button_label") }}
        </GButton>
      </div>
    </template>
  </GModal>
</template>
