import { defineStore } from "pinia";
import { useFetch } from "@/composables";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "./notifications";

type Team = {
  ActiveUserCount: number;
  CompanyId: number;
  Id: number;
  InvitedUserCount: number;
  Links: unknown | null;
  Name: string;
  PersonId: number;
  Private: boolean;
};

export const useTeamsStore = defineStore("teams", () => {
  const { showNotification } = useNotificationsStore();

  // GET all teams
  const {
    data,
    isFetching: isFetchingTeams,
    error: teamsError,
  } = useFetch(`/api/Tag`).json<Team[]>();
  const { t } = useI18n();

  const teamsList = computed(() => {
    if (newTeam.value) data.value = [...data.value, newTeam.value];
    return data.value?.sort((a, b) => a.Name.localeCompare(b.Name));
  });

  const teamsListOptions = computed(() => {
    const options =
      teamsList.value?.map((team) => ({
        label: team.Name,
        value: team.Id,
      })) || [];
    return [{ label: t("common.no_team"), value: -1 }, ...options];
  });

  // GET my teams
  const { data: myTeams } = useFetch("api/tagperson/mine", {
    initialData: [],
  }).json<Array<Record<number, string>>>();

  const myTeamsListOptions = computed(() => {
    if (!myTeams.value) return [];
    return myTeams.value?.map((team) => {
      const [key, value] = Object.entries(team)[0];
      return {
        label: value,
        value: parseInt(key),
      };
    });
  });

  // POST new team
  const newTeamPayload = ref({ Name: "" });
  const {
    data: newTeam,
    error: newTeamError,
    execute: addNewTeam,
    onFetchError: onNewTeamError,
  } = useFetch<Team>(`/api/Tag`, {
    immediate: false,
    afterFetch: (ctx) => {
      newTeamPayload.value.Name = "";
      return ctx;
    },
  })
    .post(newTeamPayload)
    .json();

  onNewTeamError(() => {
    showNotification({
      type: "danger",
      message: t("error.title"),
    });
  });

  return {
    teamsList,
    teamsListOptions,
    myTeams,
    myTeamsListOptions,
    isFetchingTeams,
    teamsError,
    newTeamPayload,
    addNewTeam,
    newTeam,
    newTeamError,
  };
});
