<script setup lang="ts">
type Type = "solid" | "light" | "outline";
type Theme = "primary" | "error" | "warning" | "success" | "info" | "neutral";
type Size = "default" | "compact";

const props = withDefaults(
  defineProps<{
    type?: Type;
    theme?: Theme;
    size?: Size;
  }>(),
  {
    type: "solid",
    theming: "primary",
    size: "compact",
  },
);

const colorClasses: Record<Type, Record<Theme, string>> = {
  solid: {
    primary: "bg-primary text-white",
    error: "bg-error text-white",
    warning: "bg-warning text-black",
    success: "bg-success text-white",
    info: "bg-info text-white",
    neutral: "bg-neutral-dark text-black",
  },
  light: {
    primary: "bg-blue-10 text-primary",
    error: "bg-error-light text-error",
    warning: "bg-warning-light text-warning-dark",
    success: "bg-success-light text-success",
    info: "bg-info-light text-info",
    neutral: "bg-neutral-medium text-black",
  },
  outline: {
    primary: "text-primary border border-primary",
    error: "text-error border border-error",
    warning: "text-warning-dark border border-warning-dark",
    success: "text-success border border-success",
    info: "text-info border border-info",
    neutral: "text-black border border-black",
  },
};

const sizeClasses: Record<Size, string> = {
  default: "py-1 px-3",
  compact: "py-0.5 px-2 ",
};
</script>
<template>
  <span
    class="rounded text-sm leading-normal tracking-[0.5px]"
    :class="`${colorClasses[props.type][props.theme]} ${sizeClasses[props.size]}`"
  >
    <slot></slot>
  </span>
</template>
