<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { GDropdown } from "@/components";
import {
  USER_STATUS,
  userStatusValueToKey,
  type UserStatusKey,
  type UserStatusValue,
} from "@/constants/user";
import UserStatusFilterItem from "./UserStatusFilterItem.vue";

const { t } = useI18n();

export type UserStatusOption = UserStatusValue | -1;

const props = defineProps<{
  isButtonSelect?: boolean;
  statusCounts?: Record<UserStatusKey, number>;
}>();

const userStatusOptions = [
  { value: -1, label: t("common.all"), countClass: "bg-info-light text-info" },
  {
    value: USER_STATUS.active,
    label: t("user_status.active"),
    countClass: "bg-success-light text-success",
  },
  {
    value: USER_STATUS.invited,
    label: t("user_status.invited"),
    countClass: "bg-warning-light text-warning",
  },
  {
    value: USER_STATUS.blocked,
    label: t("user_status.blocked"),
    countClass: "bg-error-light text-error",
  },
  {
    value: USER_STATUS.created,
    label: t("user_status.created"),
    countClass: "bg-info-light text-info",
  },
];

const availableUserStatusOptions = computed(() => {
  if (!props.statusCounts)
    return userStatusOptions.map((option) => ({ ...option, count: null }));
  const totalCount = Object.values(props.statusCounts).reduce(
    (acc, count) => acc + count,
    0,
  );
  return userStatusOptions
    .filter(({ value }) => props.statusCounts[value] !== 0)
    .map(({ value, label, countClass }) => {
      const statusKey = userStatusValueToKey[value as UserStatusValue];
      return {
        value,
        label,
        countClass,
        count: value === -1 ? totalCount : props.statusCounts[statusKey],
      };
    });
});

const selectedStatus = defineModel({
  default: -1,
  required: true,
  type: Number,
});
</script>
<template>
  <GDropdown
    v-if="!isButtonSelect"
    data-testid="user-status-select"
    :label="t('common.select_status')"
    :options="availableUserStatusOptions"
    :value="selectedStatus"
    @change="selectedStatus = $event as number"
  />
  <div
    v-else
    class="flex flex-wrap items-center gap-2"
    data-testid="user-status-selector"
  >
    <UserStatusFilterItem
      v-for="{ value, label, countClass, count } in availableUserStatusOptions"
      :key="value"
      :label="label"
      :countClass="countClass"
      :statusId="value as UserStatusOption"
      :count="count"
      :isActive="value === selectedStatus"
      @select="selectedStatus = $event as number"
    />
  </div>
</template>
