<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";

import { computed, ref, watch } from "vue";
import { GBadge, GButton } from "../../components";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import {
  IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY,
  PAGE_TOGGLE_STATE_MAP_STORAGE_KEY,
} from "@/constants";
import { redirectToOldDashboardPage } from "@/utils/redirect";
import { useMixpanel } from "@/composables";
import { useFlagStore } from "@/stores";
import FeedbackModal from "./FeedbackModal.vue";

const props = defineProps<{
  label: string;
  opt: "in" | "out";
  size?: "small" | "medium";
}>();

const isFeedbackModalOpen = ref(false);
const { t } = useI18n();
const route = useRoute();
const flagStore = useFlagStore();
const { routeMapFlag } = storeToRefs(flagStore);
const { track } = useMixpanel();
const isOptingInNewDashboard = useLocalStorage<"true" | "false">(
  IS_OPTING_IN_NEW_DASHBOARD_STORAGE_KEY,
  null,
);

const initialToggleState = computed(() => {
  if (isOptingInNewDashboard.value === null) {
    return props.opt === "out";
  }

  return isOptingInNewDashboard.value === "true";
});

const pageToggleStateMap = useLocalStorage(PAGE_TOGGLE_STATE_MAP_STORAGE_KEY, {
  [route.path]: initialToggleState.value,
});

const toggleValue = computed(() => {
  return pageToggleStateMap.value[route.path];
});

const oldRoute = computed(() => {
  return (
    routeMapFlag.value &&
    Object.values(routeMapFlag.value)?.find(
      (routeSpec) => routeSpec.new === route?.path,
    )?.old
  );
});

watch(
  toggleValue,
  (value) => {
    if (value === undefined || value === null) {
      pageToggleStateMap.value[route.path] = initialToggleState.value;
    }

    if (value === false) {
      redirectToOldDashboardPage(oldRoute.value);
    }
  },
  { immediate: true },
);

const handleToggle = (value: boolean) => {
  track("toggleDashboard", {
    target: "Old dashboard",
  });

  const currentPathSection = route?.path?.split("/")[2];
  const routeArray = Object.values(routeMapFlag.value);
  const sectionRoutes = routeArray.filter((routeSpec) =>
    routeSpec?.new?.includes(`/${currentPathSection}/`),
  );

  if (sectionRoutes.length > 0) {
    sectionRoutes.forEach((routeSpec) => {
      pageToggleStateMap.value[routeSpec.new] = value;
    });
  } else {
    pageToggleStateMap.value[route.path] = value;
  }
};

const handleOpenFeedbackModal = () => {
  track("feedbackModalOpened");
  isFeedbackModalOpen.value = true;
};
</script>
<template>
  <div
    v-if="!!oldRoute"
    class="flex w-full items-center justify-between bg-warning-light px-6 py-3"
  >
    <div class="flex items-center gap-4">
      <GBadge theme="warning">{{ t("common.badge_beta") }}</GBadge>
      <p>
        <span>{{ props.label }}</span>
        <GButton
          variant="link"
          class="text-normal px-2 underline"
          @click="handleOpenFeedbackModal"
        >
          {{ t("common.share_feedback") }}
        </GButton>
      </p>
    </div>

    <GButton @click="handleToggle(false)">
      {{ t("common.toggle_bar_button_label") }}
    </GButton>
  </div>

  <FeedbackModal
    :isOpen="isFeedbackModalOpen"
    @close="isFeedbackModalOpen = false"
  />
</template>
