<script lang="ts" setup>
import { ref } from "vue";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import BlockConfirmationStep from "./BlockConfirmationStep.vue";
import type { BulkBlockData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useI18n } from "vue-i18n";
import { useLocale, useMixpanel } from "@/composables";
import { storeToRefs } from "pinia";
import useUserActionsStore from "../../store";
import type { BulkUserActionResponse } from "../../types";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
  {
    id: "block-confirmation",
    component: BlockConfirmationStep,
  },
];

const blockData = ref<BulkBlockData>({
  emails: [],
  confirmationText: "",
});

const { t } = useI18n();
const { locale } = useLocale();
const { track } = useMixpanel();
const { performUserAction } = useUserActionsStore();
const { activeAction, isActionRequestPending } = storeToRefs(
  useUserActionsStore(),
);

const blockUsers = () => {
  performUserAction<BulkUserActionResponse>({
    request: {
      url: "/api/ActivityQueue",
      method: "DELETE",
      body: {
        command: "remove_user_by_email",
        data: blockData.value.emails.join(","),
        languageCode: locale.value,
        removeMailTxt: "",
      },
    },
    successMessage: () =>
      t("organisation.generic_action_success", {
        n: blockData.value.emails.length,
        action: t("organisation.action.block"),
      }),
    errorMessage: () =>
      t("organisation.generic_action_error", {
        n: blockData.value.emails.length,
        action: t("organisation.action.block"),
      }),
  });

  track("userBlock", {
    user_count: blockData.value.emails.length,
    action_mode: "bulk_email",
  });
};
</script>
<template>
  <BulkActionsModal
    :isOpen="activeAction === 'bulkBlockUser'"
    :data="blockData"
    :steps="steps"
    :title="t('organisation.block_users')"
    :subtitle="t('organisation.modal_bulk_block_subtitle')"
    :confirmButtonProps="{
      label: t('organisation.block'),
      variant: 'danger',
      isLoading: isActionRequestPending,
    }"
    @confirm="blockUsers()"
    @close="activeAction = null"
  />
</template>
