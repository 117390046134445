import { computed } from "vue";
import { useRoute } from "vue-router";

/**
 * Composable that provides a reactive query string from Vue Router's route.query
 *
 * @returns An object containing the reactive queryString computed property
 */
export function useQueryString() {
  const route = useRoute();

  const queryString = computed(() => {
    const params = new URLSearchParams();

    Object.entries(route.query).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((v) => params.append(key, v));
        } else {
          params.append(key, value);
        }
      }
    });

    return params.toString();
  });

  return { queryString };
}
