<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useClipboard } from "@vueuse/core";
import { format } from "date-fns";
import { GButton, PageLayout } from "@/components";
import { useOScanFetch } from "@/composables/useFetch";
import { useUserStore, useToastStore } from "@/stores";
import BicolorText from "./ResultChart/BicolorText.vue";
import ResultChart from "./ResultChart/ResultChart.vue";
import ScanContributors from "./ResultChart/ScanContributors.vue";
import type { ReportResponse, CompanyUuidResponse, Contributor } from "./types";

import CalendarIcon from "@/assets/images/icons/calendar.svg";
import UserIcon from "@/assets/images/icons/user.svg";
import { computed } from "vue";

const { t } = useI18n();
const { copy } = useClipboard();
const { user } = useUserStore();
const toastStore = useToastStore();

const {
  data: companyUuid,
  isFetching: isFetchingUuid,
  execute: fetchUuid,
} = useOScanFetch(`/company-uuid/${user.CompanyId}`, {
  immediate: false,
}).json<CompanyUuidResponse>();

const { data: report, isFetching: isFetchingReport } = useOScanFetch(
  `/report/${user.CompanyId}`,
).json<ReportResponse>();

const handleCopyUuid = async () => {
  try {
    if (!companyUuid.value) {
      await fetchUuid(true);
    }
    copy(
      `${import.meta.env.VITE_O_SCAN_SURVEY_URL}?uuid=${companyUuid.value.uuid}`,
    );
    toastStore.addToast({
      header: t("common.copied"),
      body: t("common.copied_link_to_clipboard"),
    });
  } catch (error) {
    toastStore.addToast({
      header: t("error.title"),
      body: t("common.error_copy_link_to_clipboard"),
    });
  }
};

const navigateToOscan = async () => {
  try {
    if (!companyUuid.value) {
      await fetchUuid();
    }

    window.open(
      `${import.meta.env.VITE_O_SCAN_SURVEY_URL}?uuid=${
        companyUuid.value.uuid
      }`,
      "_blank",
    );
  } catch (error) {
    toastStore.addToast({
      header: t("error.title"),
      body: t("Couldn't open the survey. Please try again."),
    });
  }
};

const mapContributor = ({
  highestPotentialId,
  lowestScoreId,
  ...restContributor
}: Contributor) => ({
  ...restContributor,
  highestPotentialName: highestPotentialId
    ? report.value.growthAreas.find(
        (ga) => ga.referenceName === highestPotentialId,
      )?.name
    : "-",
  lowestScoreName: lowestScoreId
    ? report.value.growthAreas.find((ga) => ga.referenceName === lowestScoreId)
        ?.name
    : "-",
});

const formattedLastUpdated = computed(() => {
  return format(new Date(report.value.lastUpdatedAt), "dd MMMM yyyy");
});
</script>

<template>
  <!-- OScan first release was for only one language -->
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <PageLayout title="O-Scan Report" :loading="isFetchingReport">
    <div class="mx-12 mb-40 mt-12">
      <div class="flex justify-between gap-8 pb-12">
        <div class="flex flex-col">
          <BicolorText class="text-4xl md:text-4xl">
            <h3>Organisation Scan</h3>
          </BicolorText>
          <p v-if="report" class="mt-6 flex gap-8 text-sm text-grey-60">
            <!-- TODO: get last update & no of contributors -->
            <span class="flex h-2 items-center gap-2"
              ><CalendarIcon class="fill-grey-60 [&>path]:fill-grey-60" /> Last
              updated: {{ formattedLastUpdated }}</span
            >
            <span class="flex h-2 items-center gap-2"
              ><UserIcon class="fill-grey-60" /> Number of contributors:
              {{ report.contributors.length }}</span
            >
          </p>
        </div>
        <div class="flex max-w-1/2 flex-wrap gap-3">
          <!-- TODO: download report -->
          <!-- <GButton
            class="self-start"
            variant="secondary"
            size="large"
            :disabled="!report || isFetchingReport || isFetchingUuid"
          >
            Download report
          </GButton> -->
          <GButton
            @click="handleCopyUuid()"
            class="self-start"
            variant="secondary"
            :disabled="isFetchingUuid"
          >
            Copy link to the scan
          </GButton>
          <GButton
            class="self-start"
            variant="primary"
            @click="navigateToOscan()"
            :disabled="isFetchingUuid"
          >
            Get started
          </GButton>
        </div>
      </div>

      <section
        v-if="report"
        class="flex w-full flex-col border-t border-t-grey-20 bg-white px-10 pb-4"
      >
        <div class="flex w-full flex-col items-center">
          <ResultChart :growth-areas="report.growthAreas ?? null" />
          <ScanContributors
            :contributors="report.contributors.map(mapContributor)"
          />
        </div>
      </section>
      <section class="my-4" v-else-if="!isFetchingReport">
        <div
          class="flex w-full items-center justify-between gap-8 rounded-lg bg-blue-10 p-12"
        >
          <div class="max-w-md">
            <h4 class="mb-8 text-2xl text-primary">
              Fill the Organisation Scan to create the most impact in your
              organisation!
            </h4>
            <ul class="ml-4 list-disc text-sm">
              <li>Take the 5 minute quiz</li>
              <li>
                Get a reflection on your organisation by you or by your peers by
                sharing a copied link
              </li>
              <li>See in what areas your organisation can grow most</li>
              <li>Get relevant GoodHabitz content to enable growth</li>
            </ul>
          </div>
          <div>
            <img
              src="@/assets/images/o-scan.png"
              class="max-w-sm"
              alt="O-scan"
            />
          </div>
        </div>
      </section>
    </div>
  </PageLayout>
</template>
