<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useUserActionsStore from "../store";
import { storeToRefs } from "pinia";
import { GExportModal } from "@/components";

const {
  selectedUsers,
  closeModal,
  exportUsersFileHeaders,
  exportUsersFileRows,
} = useUserActionsStore();
const { activeAction } = storeToRefs(useUserActionsStore());
const { t } = useI18n();
</script>
<template>
  <GExportModal
    :isOpen="activeAction === 'export'"
    scrollBehaviour="modal"
    :title="t('organisation.export_user(s)', selectedUsers.length || 1)"
    @close="closeModal()"
    :fileName="`GH - ${t('organisation.export_filename')}`"
    :data="{ headers: exportUsersFileHeaders, rows: exportUsersFileRows() }"
    data-testId="block-modal"
    class="max-w-[30rem]"
  />
</template>
