export type EmailTagNodeJSON = {
  type: "emailTag";
  attrs: {
    text: string;
    hasError: boolean;
  };
  content?: never;
};

type PrimitiveNodeJSON = {
  type: string;
  text: string;
  content?: never;
};

export type NestedNodeJSON = {
  type: string;
  text?: never;
  content: EditorDocNodeJSON[];
};

export type EditorDocNodeJSON =
  | EmailTagNodeJSON
  | PrimitiveNodeJSON
  | NestedNodeJSON;

export const isEmailTagNode = (
  node: EditorDocNodeJSON,
  emailTagName: string,
): node is EmailTagNodeJSON => {
  return node.type === emailTagName;
};

export const isNestedNode = (
  node: EditorDocNodeJSON,
): node is NestedNodeJSON => {
  return "content" in node;
};
