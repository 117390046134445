<script setup lang="ts">
import { ref } from "vue";
import { useKeyboardNavigation } from "./useKeyboardNavigation";
import { GBadge } from "@/components/GBadge";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";

export type Tab = {
  id: string;
  label: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  hasNewBadge?: boolean;
  hasBetaBadge?: boolean;
  href?: string;
};

type TabsListProps = {
  tabs: Tab[];
  activeTabId?: string | null;
};

defineProps<TabsListProps>();
const emit = defineEmits<{
  (event: "change", id: string): void;
  (event: "focus", id: string): void;
}>();

const { t } = useI18n();
const tabsRef = ref<HTMLDivElement>();
useKeyboardNavigation(tabsRef);
</script>

<template>
  <div class="tabs" role="tablist" ref="tabsRef">
    <component
      v-for="{
        id,
        label,
        isDisabled,
        hasBetaBadge,
        hasNewBadge,
        href,
      } of tabs.filter((tab) => !tab.isHidden)"
      role="tab"
      :is="href ? RouterLink : 'button'"
      class="tab tab-bordered"
      :key="id"
      :class="{
        'tab-active': id === activeTabId,
        'tab-disabled': isDisabled,
      }"
      :to="href"
      :tabindex="id === activeTabId ? 0 : -1"
      :aria-controls="`tab-panel-${id}`"
      :aria-selected="id === activeTabId"
      :disabled="isDisabled"
      @click="!href ? emit('change', id) : undefined"
      @focus="emit('focus', id)"
    >
      <span role="heading" aria-level="2">{{ label }}</span>
      <GBadge
        v-if="hasBetaBadge || hasNewBadge"
        :variant="hasBetaBadge ? 'beta' : 'new'"
        size="compact"
        class="ml-2"
        data-testid="tab-badge"
        >{{
          t(hasBetaBadge ? "common.badge_beta" : "common.badge_new")
        }}</GBadge
      >
    </component>
  </div>
</template>
<style scoped lang="postcss">
.tabs {
  @apply relative mb-7 flex flex-col flex-wrap items-start border-b-2 md:flex-row;
}

.tab {
  @apply relative -mb-[2px] inline-flex h-8 cursor-pointer flex-wrap items-center justify-center px-4 text-center text-grey-70;
}

.tab-bordered {
  @apply border-b border-solid border-transparent;
}

.tab.tab-active {
  @apply text-primary border-b-primary;
}

.tab-disabled {
  @apply cursor-not-allowed text-grey-30;
}

.tab:hover:not(.tab-disabled) {
  @apply text-primary;
}
</style>
