<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { PageLayout } from "@/components";

const { t } = useI18n();
</script>
<template>
  <PageLayout :title="t('overview.title')"> </PageLayout>
</template>
