<script setup lang="ts">
import { computed } from "vue";
import { ListboxButton } from "@headlessui/vue";

import type { ValueType } from "./types";

import ChevronDownIcon from "../../assets/images/icons/chevron-down.svg";

type DropdownButtonTriggerProps = {
  value?: ValueType | ValueType[];
  label?: string;
  isMenuOpen: boolean;
  singleSelectedOptionLabel?: string;
  variant?: "primary" | "secondary";
  disabled?: boolean;
};

const props = withDefaults(defineProps<DropdownButtonTriggerProps>(), {
  variant: "primary",
});

const variantClasses: Record<
  NonNullable<DropdownButtonTriggerProps["variant"]>,
  string
> = {
  primary:
    "border border-grey-10 bg-white text-black hover:border-primary focus:border-primary",
  secondary:
    "bg-transparent text-white fill-white hover:bg-[#3F15A5] focus:border-white",
};

const displayValue = computed(() => {
  return Array.isArray(props.value)
    ? props.label
    : props.singleSelectedOptionLabel;
});
</script>
<template>
  <ListboxButton
    class="flex w-full items-center justify-between whitespace-nowrap rounded-lg px-4 py-[0.64rem] text-start disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-grey-10"
    :class="[{ 'border-primary': isMenuOpen }, variantClasses[variant]]"
  >
    <span class="me-2 flex-1 overflow-hidden text-ellipsis">
      {{ displayValue }}
    </span>
    <div
      v-if="Array.isArray(value) && value.length > 0 && value[0] !== null"
      class="mx-3 flex min-w-[1.5rem] justify-center rounded-full bg-primary py-0.5 text-sm text-white"
    >
      {{ value.length }}
    </div>
    <ChevronDownIcon
      class="transition-transform"
      :class="{ 'rotate-180': isMenuOpen }"
    />
  </ListboxButton>
</template>
