<script setup lang="ts">
import { GBadge } from "@/components";
import { useIntersectionObserver } from "@vueuse/core";
import { ref, onMounted } from "vue";
import type { GrowthAreaWithRecommendations } from "../types";
import RecommendationsCarousel from "./RecommendationsCarousel.vue";

const props = defineProps<{
  growthArea: GrowthAreaWithRecommendations;
  modelValue: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const growthAreaSection = ref<HTMLElement | null>(null);

onMounted(() => {
  if (growthAreaSection.value) {
    useIntersectionObserver(
      growthAreaSection.value as HTMLElement,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          emit(
            "update:modelValue",
            props.growthArea.name.replace(/[^A-Za-z0-9]/g, ""),
          );
          document
            .querySelector(
              "#ga-button-" +
                props.growthArea.name.replace(/[^A-Za-z0-9]/g, ""),
            )
            ?.scrollIntoView({ block: "start", inline: "start" });
        }
      },
    );
  }
});
</script>

<template>
  <section
    class="mb-40 grid gap-4 pt-24 last:mb-0"
    ref="growthAreaSection"
    :id="`ga-${growthArea.name.replace(/[^A-Za-z0-9]/g, '')}`"
  >
    <p class="text-2xl font-bold">{{ props.growthArea.name }}</p>
    <p class="text-sm text-grey-80">{{ props.growthArea.description }}</p>
    <div class="mb-12 flex gap-4">
      <GBadge class="rounded-full py-1.5 text-base">{{
        props.growthArea.score.description
      }}</GBadge>
      <GBadge class="rounded-full py-1.5 text-base">{{
        props.growthArea.ambition.description
      }}</GBadge>
    </div>
    <RecommendationsCarousel
      :recommendations="props.growthArea.recommendations"
      :color="props.growthArea.color"
      :backgroundColor="props.growthArea.backgroundColor"
    />
  </section>
</template>
