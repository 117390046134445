<script setup lang="ts">
import { computed, onMounted, ref, useSlots, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import CloseIcon from "@/assets/images/icons/close.svg";
import { onKeyStroke } from "@vueuse/core";
import { DIALOG_TRANSITION_MS } from "./index";

const transitionDurationSeconds = computed(() => `${DIALOG_TRANSITION_MS / 1000}s`);

const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    title?: string;
    hasCloseButton?: boolean;
    closeOnOutsideClick?: boolean;
    closeOnEscape?: boolean;
  }>(),
  {
    closeOnOutsideClick: true,
    closeOnEscape: true,
    hasCloseButton: true,
  },
);

const emit = defineEmits<{
  (event: "close"): void;
}>();

const dialog = ref<HTMLDialogElement>();
const { t } = useI18n();

watchEffect(() => {
  if (dialog.value) {
    props.isOpen ? dialog.value.showModal() : dialog.value.close();
  }
});

onKeyStroke("Escape", (e) => {
  if (!props.closeOnEscape) {
    e.preventDefault();
  } else {
    emit("close");
  }
});

const handleClickOutside = (event: MouseEvent) => {
  const dialogDimensions = dialog.value?.getBoundingClientRect();
  if (
    props.closeOnOutsideClick &&
    (event.clientX < dialogDimensions.left ||
      event.clientX > dialogDimensions.right ||
      event.clientY < dialogDimensions.top ||
      event.clientY > dialogDimensions.bottom)
  ) {
    emit("close");
  }
};
const slots = useSlots();
const title = computed(() => {
  if (props.title) return props.title;
  return slots.title?.();
});

onMounted(() => {
  if (!title.value) {
    throw new Error(
      "GModal: modal title is required, either provide a title prop or use the title slot",
    );
  }
});
</script>
<template>
  <dialog
    ref="dialog"
    @click.capture="handleClickOutside"
    class="g-modal flex-col gap-2 rounded-lg p-6 shadow-md"
  >
    <div class="flex justify-between">
      <slot name="title">
        <h2 class="text-lg font-bold text-black" data-testid="modal-title">
          {{ title }}
        </h2>
      </slot>
      <CloseIcon
        v-if="hasCloseButton"
        class="cursor-pointer"
        @click="$emit('close')"
        :title="t('common.close_modal')"
        data-testid="close-modal-button"
      />
    </div>
    <div class="flex-1">
      <slot />
    </div>
    <div
      v-if="$slots.actions"
      class="dialog-actions flex w-full justify-center md:justify-end"
    >
      <slot name="actions" />
    </div>
  </dialog>
</template>
<style>
.dialog-actions > * {
  @apply flex-1;
}

.dialog-actions > *:not(:last-child) {
  @apply mr-3;
}
.g-modal[open] {
  opacity: 1;
  display: flex;
  transform: translateY(0);
}
.g-modal {
  opacity: 0;
  display: none;
  transform: translateY(-30%);
  transition:
    opacity v-bind(transitionDurationSeconds) ease-out,
    transform v-bind(transitionDurationSeconds) ease-out,
    overlay v-bind(transitionDurationSeconds) ease-out allow-discrete,
    display v-bind(transitionDurationSeconds) ease-out allow-discrete;
}
@starting-style {
  .g-modal[open] {
    opacity: 0;
    transform: translateY(30%);
  }
}
.g-modal::backdrop {
  background-color: rgb(0 0 0 / 0);
  transition: all 0.5s allow-discrete;
}
.g-modal[open]::backdrop {
  background-color: rgb(0 0 0 / 0.25);
}
@starting-style {
  .g-modal[open]::backdrop {
    background-color: rgb(0 0 0 / 0);
  }
}
</style>
