<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import {
  GButton,
  GStateHandlerWrapper,
  GExportModal,
  GDatePicker,
} from "@/components";
import { usePersistedFilter } from "@/composables";
import { useCourseMilestonesReport } from "./useCourseMilestonesReport";
import type { CourseMilestoneTableRow, Filters } from "./types";
import { FilterPanel, GTable, TeamsSelect, UserStatusSelect } from "@/patterns";
import { reportInitialDateRange } from "../utils";
import MilestoneStatusSelect from "../components/MilestoneStatusSelect.vue";
import { useFlagStore } from "@/stores";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const { t } = useI18n();
const flagStore = useFlagStore();

const defaultSort = [{ desc: true, id: "date" }];
const initialFilters: Filters = {
  search: "",
  milestone: null,
  date: reportInitialDateRange,
  teams: [null],
  status: -1,
};

const selectedRows = ref<CourseMilestoneTableRow[]>([]);
const isSecondaryFiltersVisible = ref(false);
const isExportModalOpen = ref(false);
const { filters, resetFilters } = usePersistedFilter<Filters>(initialFilters);

const { data, isFetching, error, tableDefinition, hasForeignUsername } =
  useCourseMilestonesReport(filters);

const exportFileHeaders = computed(() => [
  t("courseMilestonesReport.tbl_col_id"),
  t("courseMilestonesReport.milestone"),
  t("reports.tbl_col_date"),
  t("courseMilestonesReport.tbl_col_course_id"),
  t("reports.tbl_col_course_title"),
  t("reports.tbl_col_name"),
  t("reports.tbl_col_email"),
  ...(hasForeignUsername.value ? [t("reports.tbl_col_foreign_username")] : []),
]);
</script>
<template>
 
    <GButton
      v-if="!flagStore.actionBarFlag"
      class="mb-4 ml-auto lg:px-12"
      @click="isExportModalOpen = true"
      :disabled="selectedRows.length === 0"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>
    <FilterPanel
      v-model:search="filters.search"
      :isSecondaryFiltersVisible="isSecondaryFiltersVisible"
      @reset="resetFilters"
      @toggleSecondaryFilters="
        isSecondaryFiltersVisible = !isSecondaryFiltersVisible
      "
    >
      <template #primaryFilters>
        <GDatePicker v-model="filters.date" :label="t('common.date')" />
        <MilestoneStatusSelect v-model="filters.milestone" />
      </template>
      <template #secondaryFilters>
        <UserStatusSelect v-model="filters.status" />
        <TeamsSelect v-model="filters.teams" />
      </template>
    </FilterPanel>
    <GStateHandlerWrapper :isLoading="isFetching" :error="error">
    <GTable
      :data="data"
      :columns="tableDefinition"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :defaultSort="defaultSort"
      :exportFn="() => (isExportModalOpen = true)"
      :hasActionBar="flagStore.actionBarFlag"
    >
      <template #date="{ value }">
        {{ value ? format(value as Date, "PPP") : "-" }}
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <GExportModal
    :isOpen="isExportModalOpen"
    fileName="GH - course milestones report"
    :data="{
      headers: exportFileHeaders,
      rows: selectedRows.map((row) => Object.values(row)),
    }"
    @close="isExportModalOpen = false"
  />
</template>
