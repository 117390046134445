<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { GButton, GStateHandlerWrapper } from "@/components";
import { useToastStore, useWebSessionStore, useFlagStore } from "@/stores";
import { FilterPanel, LanguageSelect, GTable } from "@/patterns";
import ExportModal from "../components/ExportModal.vue";
import { useDownloadScorm } from "../components/useDownloadScorm";
import ActivitySelect from "../components/ActivitySelect.vue";
import CourseSelect from "../components/CourseSelect.vue";
import LessonSelect from "../components/LessonSelect.vue";
import { useSingleActivitiesTab } from "./useSingleActivitiesTab";

import ExternalLinkIcon from "@/assets/images/icons/external-link.svg";
import CopyIcon from "@/assets/images/icons/copy.svg";
import type { Filters } from "./types";
import type { LanguageCode } from "@gh-dashboard/types";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";
import { RemoteScormContentType } from "@/types/export";

const initialFilters: Filters = {
  search: "",
  language: "en-gb",
  courseId: -1,
  lessons: [null],
  activities: [null],
};

const isExportModalOpen = ref(false);
const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
  }
};

const flagStore = useFlagStore();
const { t } = useI18n();
const filters = ref({ ...initialFilters });
const isFetchingFiltersDone = ref(false);
const {
  singleActivitiesList,
  error,
  isFetching,
  singleActivitiesTableDefinition,
  selectedRows,
  exportableData,
  trackExportSingleActivities,
} = useSingleActivitiesTab({ filters, isFetchingFiltersDone });
const toastStore = useToastStore();

const { scromWithLMSConnectorFlag } = useFlagStore();

const { downloadScormPackages, downloadRemoteScormPackages } =
  useDownloadScorm();

const { getLanguageCodeByLanguageRegion } = useWebSessionStore();
const downloadRemoteScorm = async () => {
  if (scromWithLMSConnectorFlag) {
    await downloadRemoteScormPackages(
      selectedRows.value.map((row) => ({
        contentId: row.activityCode,
        contentType: RemoteScormContentType.SingleActivity,
        isMultipleLanguage: false,
        title: row.activityName,
        locale: row.language,
      })),
    );
  } else {
    await downloadScormPackages(
      "SINGLE_ACTIVITY",
      selectedRows.value.map((row) => ({
        id: row.activityCode,
        name: row.activityName,
        title: row.activityName,
        locale: row.language,
        language: getLanguageCodeByLanguageRegion(row.language),
      })),
    );
  }
  trackExportSingleActivities(selectedRows.value);
  selectedRows.value = [];
};

const handleLTIDownloaded = () => {
  trackExportSingleActivities(selectedRows.value);
  selectedRows.value = [];
};

const handleCopyToClipboard = ({
  text,
  activityName,
}: {
  text: string;
  activityName: string;
}) => {
  navigator.clipboard.writeText(text);
  toastStore.addToast({
    header: "success",
    body: t("contentSelection.singleActivities.copy_success_message", {
      activityName,
    }),
  });
};

const updateLanguageFilter = (value: LanguageCode) => {
  filters.value = {
    ...filters.value,
    language: value,
    courseId: -1,
  };
};

const updateCourseFilter = (value: number) => {
  filters.value = {
    ...filters.value,
    courseId: value,
    lessons: [],
    activities: [],
  };
};

const updateLessonFilter = (value: number[]) => {
  filters.value = {
    ...filters.value,
    lessons: value,
    activities: [],
  };
};
</script>
<template>
  <GButton
    v-if="!flagStore.actionBarFlag"
    class="mb-4 ml-auto lg:px-12"
    @click="onStartExport"
    :disabled="selectedRows.length === 0"
  >
    <template #prefixIcon><ArrowDownToLineIcon /></template>
    {{ t("common.export") }}
  </GButton>
  <FilterPanel
    v-model:search="filters.search"
    @reset="filters = { ...initialFilters }"
  >
    <template #primaryFilters>
      <LanguageSelect
        :modelValue="filters.language"
        @update:modelValue="updateLanguageFilter"
        :isMultiChoice="false"
      />
      <CourseSelect
        :modelValue="filters.courseId"
        :language="filters.language"
        @update:modelValue="updateCourseFilter"
        @fetchingCompleted="isFetchingFiltersDone = true"
      />
      <LessonSelect
        :modelValue="filters.lessons"
        :language="filters.language"
        :courseId="filters.courseId"
        @update:modelValue="updateLessonFilter"
      />
      <ActivitySelect
        v-model="filters.activities"
        :language="filters.language"
        :courseId="filters.courseId"
        :lessons="filters.lessons"
      />
    </template>
  </FilterPanel>
  <GStateHandlerWrapper
    :isLoading="isFetching || !singleActivitiesList"
    :error="error ? {} : null"
  >
    <GTable
      :columns="singleActivitiesTableDefinition"
      :data="singleActivitiesList"
      :filter="{
        global: filters.search,
        columns: [
          { id: 'lessonId', value: filters.lessons },
          { id: 'activityName', value: filters.activities },
        ],
      }"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :selectedList="selectedRows"
      :exportFn="onStartExport"
      :hasActionBar="flagStore.actionBarFlag"
    >
      <template v-slot:activity="cellProps">
        {{ cellProps.value }}
      </template>
      <template v-slot:dateOfPublication="cellProps">
        {{ (cellProps.value as Date).toLocaleDateString() }}
      </template>
      <template v-slot:actions="cellProps">
        <div class="flex items-center gap-1">
          <GButton
            variant="secondary"
            class="ms-2"
            @click="
              handleCopyToClipboard({
                text: cellProps.row.webLink,
                activityName: cellProps.row.activity,
              })
            "
          >
            <template #prefixIcon><CopyIcon class="text-primary" /></template>
            {{ t("common.copy") }}
          </GButton>
          <GButton
            variant="secondary"
            :href="cellProps.row.webLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            <template #prefixIcon>
              <ExternalLinkIcon class="h-4 w-4 fill-primary" />
            </template>
            {{ t("common.open") }}
          </GButton>
        </div>
      </template>
    </GTable>
  </GStateHandlerWrapper>

  <ExportModal
    :isOpen="isExportModalOpen"
    ltiFileName="gh-single-activities-export"
    :data="exportableData"
    :exportTypes="['lti', 'scorm']"
    @close="isExportModalOpen = false"
    @exportToScorm="downloadRemoteScorm"
    @exportToLtiSuccess="handleLTIDownloaded"
  />
</template>
