<script setup lang="ts">
import type { UserStatusOption } from "./UserStatusSelect.vue";

const props = defineProps<{
  countClass: string;
  label: string;
  statusId: UserStatusOption;
  count: number | null;
  isActive: boolean;
}>();

const emit = defineEmits<{
  (e: "select", status: UserStatusOption): void;
}>();
</script>
<template>
  <div
    @click="emit('select', props.statusId)"
    class="flex cursor-pointer items-center gap-2 rounded-md border border-grey-10 px-4 py-[0.39rem] hover:border-primary"
    :class="{ 'border-primary': isActive }"
  >
    <span
      v-show="props.count !== null"
      class="min-h-7 min-w-4 rounded p-1 text-sm"
      :class="countClass"
      >{{ props.count ?? " " }}</span
    >
    <span class="leading-8 text-grey-80" :class="{ 'text-primary': isActive }">
      {{ label }}
    </span>
  </div>
</template>
