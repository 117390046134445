<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import ChooseTeamsStep from "./ChooseTeamsStep.vue";
import type { BulkAddToTeamsData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useUserActionsStore } from "../../store";
import { storeToRefs } from "pinia";
import { useMixpanel } from "@/composables";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
    additionalProps: {
      blockCurrentUserEmail: false,
    },
  },
  {
    id: "choose-teams",
    component: ChooseTeamsStep,
  },
];

const addToTeamsData = ref<BulkAddToTeamsData>({
  emails: [],
  teamIds: [],
});

const { t } = useI18n();
const { performUserAction } = useUserActionsStore();
const { track } = useMixpanel();
const { activeAction, isActionRequestPending } = storeToRefs(
  useUserActionsStore(),
);

const addToTeams = () => {
  performUserAction({
    request: {
      url: "/api/TagPerson",
      method: "POST",
      body: {
        TagId: addToTeamsData.value.teamIds,
        Email: addToTeamsData.value.emails,
        PersonId: [],
        StartDate: new Date().toISOString(),
        DurationUnit: "YYYY",
        Duration: 100,
      },
    },
    successMessage: () =>
      t("organisation.generic_action_success", {
        n: addToTeamsData.value.emails.length,
        action: t("organisation.action.add_to_team"),
      }),
    errorMessage: () =>
      t("organisation.generic_action_error", {
        n: addToTeamsData.value.emails.length,
        action: t("organisation.action.add_to_team"),
      }),
  });

  track("userAddToTeam", {
    user_count: addToTeamsData.value.emails.length,
    action_mode: "bulk_email",
  });
};
</script>
<template>
  <BulkActionsModal
    :isOpen="activeAction === 'bulkAddToTeam'"
    :data="addToTeamsData"
    :steps="steps"
    :title="t('organisation.add_to_team')"
    :confirmButtonProps="{
      label: t('organisation.add_to_team', addToTeamsData.teamIds.length),
      variant: 'primary',
      isLoading: isActionRequestPending,
    }"
    @confirm="addToTeams()"
    @close="activeAction = null"
  />
</template>
