<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { TextInput } from "@/components";

const { t } = useI18n();
const teamName = defineModel<string>({ required: true });
</script>

<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-2">
        <TextInput
          name="teamName"
          fullWidth
          :label="t('organisation.add_new_team_input_label')"
          :placeholder="t('organisation.add_new_team_input_placeholder')"
          v-model="teamName"
        />
      </div>
    </div>
  </div>
</template>
