import { computed, ref, watch, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import type { SingleActivity } from "@gh-dashboard/types";

import { useFetch } from "@/composables";
import type { TableColumn } from "@/patterns";
import { useCourseStore } from "@/stores";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { multiSelectFilter } from "@/utils/filterFns";
import { type LanguageCode } from "@/utils/i18n";
import { parseStringToJSON } from "@/utils/misc";
import type { Filters } from "./types";

type ColumnAccessor =
  | "language"
  | "courseName"
  | "courseId"
  | "lesson"
  | "lessonId"
  | "activityName"
  | "activityId"
  | "duration"
  | "lti11Link"
  | "lti13Link"
  | "webLink";

export type SingleActivityTableDataRow = {
  id: number;
  activityName: string;
  activityType: string;
  activityId: number;
  courseName: string;
  courseId: number;
  lesson: string;
  lessonId: number;
  duration: number;
  language: LanguageCode;
  lti11Link: string;
  lti13Link: string;
  webLink: string;
  activityCode: string;
};

type ActivityListResponse = {
  Items: SingleActivity[];
  Page: number;
  pageSize: number;
  TotalCount: number;
};

type UseSingleActivitiesTabInput = {
  filters: Ref<Filters>;
  isFetchingFiltersDone: Ref<boolean>;
};

export const useSingleActivitiesTab = ({
  filters,
  isFetchingFiltersDone,
}: UseSingleActivitiesTabInput) => {
  const courseStore = useCourseStore();
  const { t } = useI18n();

  const searchParams = computed(() => {
    const s = new URLSearchParams();
    if (!isFetchingFiltersDone.value || filters.value.courseId === -1) {
      return s.toString();
    }

    s.append("language", filters.value.language.toLowerCase());
    s.append("courseId", filters.value.courseId.toString());
    s.append("page", "1");
    s.append("pageSize", "9999");
    return s.toString();
  });

  const {
    isFetching,
    data,
    error,
    isFinished,
    execute: fetchActivities,
  } = useFetch(() => `api/activityList?${searchParams.value}`, {
    immediate: false,
  }).json<ActivityListResponse>();

  watch(searchParams, (updatedSearchParams) => {
    if (updatedSearchParams) fetchActivities();
  });

  const singleActivitiesTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "language",
      headerLabel: "Language",
      accessor: "language",
      visibleFrom: false,
    },
    {
      id: "activityName",
      headerLabel: "Activity",
      accessor: "activityName",
      filterFnName: multiSelectFilter,
      smallScreenPosition: "center",
    },
    {
      id: "activityId",
      accessor: "activityId",
      visibleFrom: false,
    },
    {
      id: "courseId",
      visibleFrom: false,
      accessor: "courseId",
    },
    {
      id: "courseName",
      headerLabel: "Course",
      accessor: "courseName",
    },
    {
      id: "lesson",
      headerLabel: "Lesson",
      accessor: "lesson",
    },
    {
      id: "lessonId",
      accessor: "lessonId",
      visibleFrom: false,
      filterFnName: multiSelectFilter,
    },
    {
      id: "duration",
      headerLabel: "Duration (minutes)",
      accessor: "duration",
    },
    {
      id: "lti11Link",
      accessor: "lti11Link",
      visibleFrom: false,
    },
    {
      id: "lti13Link",
      accessor: "lti13Link",
      visibleFrom: false,
    },
    {
      id: "webLink",
      accessor: "webLink",
      visibleFrom: false,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const singleActivitiesList = computed<SingleActivityTableDataRow[]>(() =>
    data.value?.Items?.map((activity) => ({
      id: activity.ActivityId,
      activityName:
        activity.ActivityName ??
        parseStringToJSON(activity.ActivityTypeTranslations || "")?.[
          activity.LanguageRegion
        ] ??
        activity.ActivityType,
      activityType: activity.ActivityType || "",
      activityCode: activity.ActivityCode,
      activityId: activity.ActivityId,
      courseName: activity.CourseName,
      courseId: activity.CourseId,
      lesson: activity.ModuleName,
      lessonId: activity.ModuleId,
      duration: activity.Duration,
      language: activity.LanguageRegion,
      lti11Link: activity.LtiLink,
      lti13Link: `https://my.goodhabitz.com/${
        activity.LanguageRegion
      }/course/${courseStore.getCourseGuid(activity.CourseId)}/1?activityId=${
        activity.ActivityId
      }`,
      webLink: `${courseStore.getCourseLink(
        activity.CourseId,
        activity.PageName,
        activity.Lang,
      )}/single/${activity.ActivityId}`,
    })),
  );

  const selectedRows = ref<SingleActivityTableDataRow[]>([]);
  const exportableData = computed(() => {
    const columns: { id: string; header: string }[] = [
      {
        id: "activityName",
        header: t("contentSelection.singleActivities.txt_activity"),
      },
      {
        id: "courseName",
        header: t("contentSelection.singleActivities.txt_course"),
      },
      {
        id: "lesson",
        header: t("contentSelection.singleActivities.txt_lesson"),
      },
      {
        id: "lti11Link",
        header: t("contentSelection.txt_xls_lti11_link"),
      },
      {
        id: "lti13Link",
        header: t("contentSelection.txt_xls_lti13_link"),
      },
      {
        id: "webLink",
        header: "Web Link",
      },
    ];
    const dataSource: SingleActivityTableDataRow[] = [];

    selectedRows.value.forEach((row) => {
      const rowMeta = singleActivitiesList.value?.find(
        (activity) => activity.id === row.id,
      );
      if (rowMeta) {
        dataSource.push(rowMeta);
      }
    });

    return { columns, dataSource };
  });

  return {
    isFetching: computed(() => isFetching.value || courseStore.isFetching),
    isFinished,
    error,
    singleActivitiesList,
    singleActivitiesTableDefinition,
    selectedRows,
    exportableData,
    trackExportSingleActivities,
  };
};

const trackExportSingleActivities = (
  selectedActivities: SingleActivityTableDataRow[],
) => {
  trackMixPanelEvent("singleActivitiesExport", {
    count: selectedActivities.length,
    activities: selectedActivities.map((activity) => ({
      id: activity.id,
      type: activity.activityType,
      course: activity.courseName,
      lesson: activity.lesson,
    })),
    context: "Connect Page",
  });
};
